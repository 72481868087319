import { groupBy, map, sortBy, memoize } from 'lodash';
import { translate } from '@td/shared_utils';
import {
  CONSULTS_THAT_CAN_REFER_TO_ITSELF,
  SERVICE_PLATFORM_CODES,
  SES_CCM_SPECIALTY_PRIORITY,
  SES_SPECIALTY_PRIORITY,
  WHITELISTED_SES_SPECIALTIES
} from './constants';

export const groupSpecialties = (specialties) => {
  const entries = Object.entries(
    groupBy(specialties, spec => {
      if (spec.servicingPlatformCode === SERVICE_PLATFORM_CODES.EMS) return SERVICE_PLATFORM_CODES.Teladoc;
      else return spec.servicingPlatformCode;
    })
  );
  return map(entries, ([servicingPlatformCode, specialties]) => ({
    servicingPlatformCode,
    specialties
  }));
};

export const translateServiceFeature = featureCode =>
  translate(null, 'provider_member_specialties.specialties_features', featureCode);

export const mapServiceCodesToNames = (serviceCodes, services) =>
  map(serviceCodes, serviceCode => {
    const service = services.find(s => s.code === serviceCode);
    return service && service.name;
  });

export const filteredSpecialties = specialties => {
  const filteredEntries = specialties.filter(specialty => {
    if (specialty.sesProvider) {
      return true;
    }

    return specialty.code === 'VPC' ? specialty.specialtyFlavors.includes(specialty.code) : true;
  });
  return filteredEntries;
};

export const isServiceReferrable = (service, consultSpecialtyName) => service.sesProvider || (
  (service.name !== consultSpecialtyName || CONSULTS_THAT_CAN_REFER_TO_ITSELF.includes(service.name)) &&
  (consultSpecialtyName === 'Primary Care' ? service.code !== 'GENMEDICAL' : true) &&
  (service.code === 'VPC' ? service.specialtyFlavors.includes(service.code) : true) &&
  service.code !== 'PROVIDERREFERRAL' &&
  service.referralType
);

export const _getSesMemberSpecialtiesConfig = () => {
  try {
    const consultForm = document.querySelector('#consult_form');

    if (!consultForm) {
      return { enabled: false, ccmShowRecruitableOnly: false };
    }

    const { consultSpecialtyCd: specialtyCd, providerId } = consultForm.dataset;
    const { ses_member_specialties: { enabled, data: { specialties, ccm_show_recruitable_only: ccmShowRecruitableOnly } } } = window.FEATURE_TOGGLES_DATA;
    const config = specialties[specialtyCd];
    const avaiableForProvider = (Array.isArray(config) && config.includes(providerId)) || config === true;

    return { enabled: enabled && avaiableForProvider, ccmShowRecruitableOnly };
  } catch (e) {

    return { enabled: false, ccmShowRecruitableOnly: false };
  }
};

export const getSesMemberSpecialtiesConfig = memoize(_getSesMemberSpecialtiesConfig);

export const fetchMemberData = () => {
  const formNode = document.querySelector('#consult_form');

  if (!formNode) {
    return;
  }

  const member = JSON.parse(formNode.dataset.member);
  const consultGroupId = formNode.dataset.consultGroupId.toString();

  return { ...member, consultGroupId };
};

export const sortSesSpecialtiesByPriority = (specialties) => {
  return sortBy(specialties, ({ name }) => {
    if (SES_CCM_SPECIALTY_PRIORITY.includes(name)) {
      return SES_CCM_SPECIALTY_PRIORITY.indexOf(name);
    }

    if (SES_SPECIALTY_PRIORITY.includes(name)) {
      return SES_SPECIALTY_PRIORITY.indexOf(name) + 100;
    }

    return 1000;
  }, ['displayName']);
};

export const groupSesProviderSpecialties = (specialties) => {
  const { ccmShowRecruitableOnly } = getSesMemberSpecialtiesConfig();

  const allowedSpecialties = specialties.filter(({ eligible, recruitable, servicingPlatformCode, code }) => {
    if (!WHITELISTED_SES_SPECIALTIES.includes(code)) {
      return false;
    }

    if (servicingPlatformCode === 'SERVICEPLATFORM_CHRONICCARE' && ccmShowRecruitableOnly) {
      return eligible && recruitable;
    }

    return eligible;
  });

  const groupedSpecialties = groupSpecialties(allowedSpecialties);

  return sortBy(map(groupedSpecialties, (item) => ({
    ...item,
    sesProvider: true,
    specialties: sortSesSpecialtiesByPriority(item.specialties),
  })), ['servicingPlatformCode']);
};
