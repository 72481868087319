
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { get, has } from 'lodash';
import moment from 'moment-timezone';
import RECOMMENDED_WORKING_HOURS_QUERY from '../queries/recommended-working-hours.graphql';
import SET_RECOMMENDED_WORKING_HOURS_FLAG_MUTATION from '../mutations/set-recommended-working-hours-flag.graphql';
import { formatRecommendedWorkingHours } from '../utils/formatters';
import { recommendedWorkingHoursToggleEnabledForSpecialty } from '../utils/helpers';
import { DEFAULT_DAYS_LIMIT } from '../constants';

const RecommendedWorkingHoursContext = React.createContext({});

export const RecommendedWorkingHoursProvider = ({
  providerId,
  providerSpecialties,
  providerSchedulingTimeBuffer,
  recommendWorkHourFlg: initialRecommendedWorkingHoursFlag,
  canEditSchedule,
  ...props
}) => {
  const [recommendedWorkingHoursFlag, setRecommendedWorkingHoursFlag] = useState(initialRecommendedWorkingHoursFlag);
  const { startDate, endDate } = useMemo(() => {
    const startDate = moment().toDate();
    const endDate = moment()
      .add(get(window.FEATURE_TOGGLES_DATA, 'recommended_working_hours.data.days_limit') || DEFAULT_DAYS_LIMIT, "days")
      .endOf("day")
      .toDate();

    return { startDate, endDate };
  }, []);

  const [
    fetchRecommendedWorkingHours,
    { data, refetch: refetchRecommendedWorkingHours }
  ] = useLazyQuery(RECOMMENDED_WORKING_HOURS_QUERY, {
    variables: {
      providerId,
      dateTimeRange: {
        startDate,
        endDate
      }
    }
  });

  const [updateRecommendedWorkingHoursFlag] = useMutation(SET_RECOMMENDED_WORKING_HOURS_FLAG_MUTATION, {
    onCompleted: ({ setProviderRecommendedWorkingHoursFlag: { recommendWorkHourFlg, success } }) => {
      if (success) {
        setRecommendedWorkingHoursFlag(recommendWorkHourFlg);

        if (recommendWorkHourFlg) {
          if (!refetchRecommendedWorkingHours) {
            fetchRecommendedWorkingHours();
          } else {
            refetchRecommendedWorkingHours();
          }
        }
      }
    }
  });

  const enabledForSpecialty = useMemo(
    () => recommendedWorkingHoursToggleEnabledForSpecialty(providerSpecialties),
    [providerSpecialties]
  );

  const recommendedWorkingHoursIsEnabled = useMemo(
    () => canEditSchedule && enabledForSpecialty,
    [canEditSchedule, enabledForSpecialty]
  );

  const recommendedWorkingHours = useMemo(() => {
    if (
      recommendedWorkingHoursFlag &&
      recommendedWorkingHoursIsEnabled &&
      has(data, 'providerProfile.recommendedWorkingHours')
    ) {
      return formatRecommendedWorkingHours(
        get(data, 'providerProfile.recommendedWorkingHours'),
        providerSpecialties,
        providerSchedulingTimeBuffer,
      );
    }

    return [];
  }, [
    data,
    recommendedWorkingHoursFlag,
    recommendedWorkingHoursIsEnabled,
    providerSpecialties,
    providerSchedulingTimeBuffer
  ]);

  const refreshRecommendedWorkingHours = useCallback(() => {
    if (recommendedWorkingHoursFlag && recommendedWorkingHoursIsEnabled) {
      if (!refetchRecommendedWorkingHours) {
        fetchRecommendedWorkingHours();
      } else {
        refetchRecommendedWorkingHours();
      }
    }
  }, [
    recommendedWorkingHoursFlag,
    recommendedWorkingHoursIsEnabled,
    fetchRecommendedWorkingHours,
    refetchRecommendedWorkingHours
  ]);

  return (
    <RecommendedWorkingHoursContext.Provider
      {...props}
      value={{
        recommendedWorkingHours,
        refreshRecommendedWorkingHours,
        recommendedWorkingHoursFlag,
        updateRecommendedWorkingHoursFlag,
        recommendedWorkingHoursIsEnabled
      }}
    />
  );
};

RecommendedWorkingHoursProvider.propTypes = {
  providerId:                   PropTypes.number.isRequired,
  providerSpecialties:          PropTypes.arrayOf(PropTypes.string).isRequired,
  providerSchedulingTimeBuffer: PropTypes.number.isRequired,
  recommendWorkHourFlg:         PropTypes.bool,
  canEditSchedule:              PropTypes.bool
};

export const useRecommendedWorkingHours = () => {
  const context = useContext(RecommendedWorkingHoursContext);

  if (context === undefined) {
    throw new Error('useRecommendedWorkingHours must be used within a RecommendedWorkingHoursProvider');
  }

  return context;
};
