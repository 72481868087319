import { connect } from 'react-redux';

import { selectedTabChange } from '../../actions/symptoms-actions';
import SymptomTabs from './symptom-tabs';

const mapStateToProps = state => ({
  selectedTabIndex: state.symptomTabs.selectedTabIndex,
  notApplicableSymptomOptionHidden: state.chiefComplaint.notApplicableSymptomOptionHidden,
});

const mapDispatchToProps = {
  changeSelectedTab: selectedTabChange
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SymptomTabs);
