import VimSesClient from '@td/vim_ses_client';
import { forkJoin, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { get, groupBy, find } from 'lodash';
import GET_REF_SERVICE_SPECIALTIES_QUERY from '../queries/get-ref-service-specialties.graphql';
import getGraphqlClient from './graphql-client';
import fetchTasEligibility from './fetch-tas-eligibility';
import { SPECIALTY_MENTAL_HEALTH } from '../constants';

const fetchRawEligibility = ({ firstName, lastName, dob }, sesClient = undefined) => {
  const vimSesClient = sesClient || new VimSesClient({ sesApiHost: window.VIM_SES_API_URL });
  return vimSesClient.getEligibility({ firstName, lastName, dob }).pipe(
    map((response) => response.eligibilities
      .flatMap((eligibility) => eligibility.groups)
      .flatMap(({ teladocGroupId, serviceSpecialties }) => serviceSpecialties.map(({ name, eligible, recruitable }) => ({
        teladocGroupId,
        name,
        eligible,
        recruitable,
        sesProvider: true,
        specialtyFlavors: [],
        features: [],
      })))),
  );
};

const getRefServiceSpecialtiesByName = () => getGraphqlClient().query({ query: GET_REF_SERVICE_SPECIALTIES_QUERY })
  .then(({ data }) => groupBy(get(data, 'refServiceSpecialties', []), 'name'));

const fetchSesSpecialties = ({ id, stateCode, firstName, lastName, dob, consultGroupId }, sesClient = undefined) => forkJoin([
  fetchRawEligibility({ firstName, lastName, dob }, sesClient),
  getRefServiceSpecialtiesByName(),
]).pipe(
  map(([specialties, refSpecialtiesByName]) => {
    return specialties.map((specialty) => {
      if (specialty.teladocGroupId !== consultGroupId) {
        return;
      }

      const refSpecialty = get(refSpecialtiesByName, [specialty.name, '0']);

      if (!refSpecialty) {
        return;
      }

      return { ...refSpecialty, ...specialty };
    })
      .filter((value) => !!value);
  }),
  mergeMap((sesSpecialties) => {
    const mhSpecialty = find(sesSpecialties, ({ code }) => code === SPECIALTY_MENTAL_HEALTH);

    if (mhSpecialty && mhSpecialty.eligible) {
      return fetchTasEligibility({ id, stateCode, code: SPECIALTY_MENTAL_HEALTH }).pipe(
        map((tasSpecialties) => {
          const features = get(find(tasSpecialties, { code: SPECIALTY_MENTAL_HEALTH }), 'features', []);

          if (features.length > 0) {
            mhSpecialty.features = features;
          }

          return sesSpecialties;
        }),
      );
    }

    return of(sesSpecialties);
  }),
);

export default fetchSesSpecialties;
