import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { PROVIDERLOCATION_OTHER } from '../constants';

const LocationForm = ({
  locationList,
  currentLocationType,
  currentLocationText,
  handleLocationTypeChange,
  handleLocationTextChange,
  validationError
}) => {
  const filterCasualInputs = () =>
    locationList.filter(location_object => location_object.code !== PROVIDERLOCATION_OTHER);

  const renderCasualInput = location_object => (
    <div className="location-radio-btn" key={location_object.name}>
      <input
        type="radio"
        id={location_object.name}
        key={location_object.name}
        name="location_type"
        value={location_object.code}
        onChange={handleLocationTypeChange}
        checked={currentLocationType === location_object.code}
      />
      <label className="location-radio-btn-label" htmlFor={location_object.name}>
        {location_object.name}
      </label>
    </div>
  );

  const filterOtherInput = () =>
    locationList.filter(location_object => location_object.code === PROVIDERLOCATION_OTHER);

  const renderOtherInput = location_object => (
    <div className="location-radio-btn" key={location_object.name}>
      <input
        type="radio"
        id={location_object.name}
        key={location_object.name}
        name="location_type"
        value={location_object.code}
        onChange={handleLocationTypeChange}
        checked={currentLocationType === location_object.code}
      />
      <label className="location-radio-btn-label" htmlFor={location_object.name}>
        Other...
      </label>
      {currentLocationType === PROVIDERLOCATION_OTHER && (
        <input
          type="text"
          placeholder="Enter location (required)"
          name="location_text"
          value={currentLocationText}
          onChange={handleLocationTextChange}
          className="location-text-input"
        />
      )}
    </div>
  );

  return (
    <div>
      <h4
        className="location-form-title"
        id="location_form_title"
      >
        Location during this shift:
      </h4>
      <div
        className="location-radio-btns"
        role="radiogroup"
        aria-labelledby="location_form_title"
      >
        {filterCasualInputs().map(renderCasualInput)}
        {filterOtherInput().map(renderOtherInput)}
      </div>
      {validationError && <div className="error-msg">{validationError}</div>}
    </div>
  );
};

export default LocationForm;
