import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ width = '14', height = '14', fill = '#005c95' }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>icon - close</title>
    <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g
        fill={fill}
        className="icon-fill"
        id="Modal-/-parts-/-02-Header-"
        transform="translate(-656.000000, -28.000000)"
      >
        <g id="action-/-delete-/-e-remove-2" transform="translate(628.000000, 0.000000)">
          <path
            d="M50.8806214,29.1193786 C51.3995187,29.6382759 51.4300421,30.46061 50.9721915,31.0152057 L50.8806214,31.1159154 L41.9958823,39.9998823 L50.8806214,48.8840846 C51.4319498,49.435413 51.4319498,50.329293 50.8806214,50.8806214 C50.3617241,51.3995187 49.53939,51.4300421 48.9847943,50.9721915 L48.8840846,50.8806214 L39.9998823,41.9958823 L31.1159154,50.8806214 C30.564587,51.4319498 29.670707,51.4319498 29.1193786,50.8806214 C28.6004813,50.3617241 28.5699579,49.53939 29.0278085,48.9847943 L29.1193786,48.8840846 L38.0028823,39.9998823 L29.1193786,31.1159154 C28.5680502,30.564587 28.5680502,29.670707 29.1193786,29.1193786 C29.6382759,28.6004813 30.46061,28.5699579 31.0152057,29.0278085 L31.1159154,29.1193786 L39.9998823,38.0028823 L48.8840846,29.1193786 C49.435413,28.5680502 50.329293,28.5680502 50.8806214,29.1193786 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

CloseIcon.propTypes = {
  fill:   PropTypes.string,
  width:  PropTypes.string,
  height: PropTypes.string
};

export default CloseIcon;
