import React from 'react';
import { connect } from 'react-redux';
import { setFollowupReduxState } from '../state-managment/redux';
import ConsultFollowup from './consult-followup';
import useConsultFollowup from '../state-managment/use-consult-followup';
import { fetchMemberSpecialties } from '../state-managment/actions';

const ConsultFollowupContainer = ({
  memberId,
  consultSpecialtyName,
  stateCode,
  noFurtherCareMinDays,
  noFurtherCareMaxDays,
  initialValues,
  reduxState,
  setReduxState,
  providerScheduledAppointmentAllowed,
  communicationMethod,
  memberHasPcp,
  continueTreatment,
  patientManagementToolsV2Enabled,
  isMentalHealth,
  fieldErrors,
  terminateReasonCode,
  isMyleIntegrated,
  fetchSpecialties,
}) => {
  const logic = useConsultFollowup({
    memberId,
    consultSpecialtyName,
    stateCode,
    reduxState,
    setReduxState,
    noFurtherCareMinDays,
    noFurtherCareMaxDays,
    initialValues,
    communicationMethod,
    providerScheduledAppointmentAllowed,
    fetchSpecialties,
  });

  return (
    <ConsultFollowup
      {...logic}
      providerScheduledAppointmentAllowed={providerScheduledAppointmentAllowed}
      noFurtherCareMaxDays={noFurtherCareMaxDays}
      memberHasPcp={memberHasPcp}
      continueTreatment={continueTreatment}
      patientManagementToolsV2Enabled={patientManagementToolsV2Enabled}
      isMentalHealth={isMentalHealth}
      fieldErrors={fieldErrors}
      consultSpecialtyName={consultSpecialtyName}
      terminateReasonCode={terminateReasonCode}
      isMyleIntegrated={isMyleIntegrated}
    />
  );
};

const ConsultFollowupContainerConnected = connect(
  state => {
    const followup = state.consultNavigation.items.find(i => i.key === 'followup');
    return { reduxState: state.consultFollowup, fieldErrors: (followup && followup.errors) || [] };
  },
  (dispatch) => ({
    setReduxState: upcommingState => dispatch(setFollowupReduxState(upcommingState)),
    fetchSpecialties: (member) => dispatch(fetchMemberSpecialties(member)),
  }),
)(ConsultFollowupContainer);

export default ConsultFollowupContainerConnected;
