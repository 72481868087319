import React from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import AssociatedSymptoms from './associated-symptoms-container';
import AdditionalSymptoms from './additional-symptoms-container';
import AddSymptom from './add-symptom-container';
import './styles.scss';

const SymptomTabs = ({ selectedTabIndex, changeSelectedTab, notApplicableSymptomOptionHidden }) => (
  <div className='symptom-tabs'>
    <h3>
      Symptoms (select at least one symptom from the below list(s) or from the Add a Symptom search)
    </h3>
    <div className='symptom-option-legend'>
      {!notApplicableSymptomOptionHidden && (
        <p>
          n/a = Symptom is not applicable to this consultation
        </p>
      )}
      <p>
        No = Patient acknowledges they do not have the Symptom
      </p>
      <p>
        Yes = Patient acknowledges they have the Symptom
      </p>
    </div>
    <Tabs
      forceRenderTabPanel
      selectedIndex={selectedTabIndex}
      onSelect={(index, lastIndex) => {
        if (index === lastIndex) {
          return true;
        }

        changeSelectedTab(index);
      }}
    >
      <TabList>
        <Tab>Associated Symptoms</Tab>
        <Tab>Additional Symptoms</Tab>
      </TabList>

      <TabPanel>
        <AssociatedSymptoms />
      </TabPanel>
      <TabPanel>
        <AdditionalSymptoms />
      </TabPanel>
    </Tabs>
    <hr />
    <AddSymptom />
  </div>
);

SymptomTabs.propTypes = {
  selectedTabIndex:  PropTypes.number.isRequired,
  changeSelectedTab: PropTypes.func.isRequired,
  notApplicableSymptomOptionHidden: PropTypes.bool.isRequired,
};

export default SymptomTabs;
