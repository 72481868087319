import { get } from 'lodash';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';
import getGraphqlClient from './graphql-client';
import SERVICE_SPECIALTIES_QUERY from '../queries/service-specialties.graphql';

const fetchTasEligibility = ({ id, stateCode }) => from(getGraphqlClient().query({ query: SERVICE_SPECIALTIES_QUERY, variables: { memberId: id, stateCode } }))
  .pipe(
    map(({ data }) => get(data, 'member.serviceSpecialties', [])),
  );

export default fetchTasEligibility;
