import { ofType } from 'redux-observable';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { concat, from, of } from 'rxjs';

import fetchMemberEligibility from '../api/fetch-member-eligibility';
import {
  fetchMemberSpecialtiesFulfilled,
  fetchMemberSpecialtiesStarted,
} from './actions';
import { FETCH_MEMBER_SPECIALTIES } from './action-types';

export const fetchMemberSpecialtiesEpic = (action$, state$) => action$.pipe(
  ofType(FETCH_MEMBER_SPECIALTIES),
  filter(() => !state$.value.consultFollowup.teladocServicesLoading),
  mergeMap((action) => concat(
    of(fetchMemberSpecialtiesStarted()),
    from(fetchMemberEligibility(action.payload)).pipe(map((data) => fetchMemberSpecialtiesFulfilled(data))),
  )),
  catchError(() => of(fetchMemberSpecialtiesFulfilled([]))),
);
