import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';
import styles from './followup-specialties.module.scss';
import {
  groupSpecialties,
  filteredSpecialties,
  translateServiceFeature,
  groupSesProviderSpecialties,
  getSesMemberSpecialtiesConfig,
} from '../helpers';
import TextWithPopup from './text-with-popup';
import cx from 'classnames';
import { NameAttributes } from '../dom-integration';
import { InfoIcon } from '../../icon-web';
import FollowupSpecialtiesPlatform from './followup-specialties-platform';

export const TRANSLATION_SCOPE = 'provider_member_specialties';

export const getPartnerDependentAttrs = spec => {
  if (spec.referralType === 'PARTNER') {
    return {
      name:  NameAttributes.referrals.partnerServices.html,
      value: spec.code
    };
  } else {
    return {
      name:
        spec.serviceOfferingCode === 'WELLNESS'
          ? NameAttributes.referrals.teladocServices.program.html
          : NameAttributes.referrals.teladocServices.service.html,
      value: spec.name
    };
  }
};

export const mapSpecialty = (spec, isServiceSelected) => {
  const inputProps = {
    ...getPartnerDependentAttrs(spec),
    type:    'checkbox',
    checked: isServiceSelected(spec.code)
  };
  return {
    ...spec,
    inputProps,
    features:
      spec.features &&
      spec.features.map(f => ({
        ...f,
        inputProps: {
          name:    NameAttributes.referrals.teladocServices.serviceFeatures.html(spec.displayName),
          checked: isServiceSelected(spec.code, f.code)
        }
      }))
  };
};

const FollowupSpecialties = ({ className, specialties, isServiceSelected, onServiceClick, pclmIntegrationEnabled }) => {
  const filteredServiceSpecialties = filteredSpecialties(specialties);
  const platformsWithSpecialties = getSesMemberSpecialtiesConfig().enabled
    ? groupSesProviderSpecialties(filteredServiceSpecialties)
    : groupSpecialties(filteredServiceSpecialties);
  const translationScopeServicePlatform = pclmIntegrationEnabled
    ? `${TRANSLATION_SCOPE}.service_platforms_mental_health`
    : `${TRANSLATION_SCOPE}.service_platforms`;

  return (
    <div className={cx(styles.followupSpecialtiesRoot, className)}>
      {platformsWithSpecialties.map(platform => (
        <FollowupSpecialtiesPlatform
          key={platform.servicingPlatformCode}
          platform={platform}
          translationScope={translationScopeServicePlatform}
          isServiceSelected={isServiceSelected}
          onServiceClick={onServiceClick}
        />
      ))}
    </div>
  );
};

FollowupSpecialties.propTypes = {
  className: PropTypes.string,
  specialties: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      servicingPlatformCode: PropTypes.string.isRequired,
      serviceOfferingCode: PropTypes.string.isRequired,
      referralType: PropTypes.string.isRequired,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired
        })
      )
    })
  )
};

export const FollowupSpecialtiesItem = ({ speciality, onInputClick, showInfoIcon = false }) => {
  const hasMultipleFeatures = speciality.features && speciality.features.length > 1;
  return (
    <div className={styles.specialtyRoot}>
      <div className={styles.specialty}>
        <label className={styles.specialtyInputWrapper}>
          <input onChange={() => onInputClick(speciality.code)} {...speciality.inputProps} />
          <div className={styles.specialtyName}>
            {speciality.displayName}
            {speciality.recruitable && (
              <span className={styles.specialtyTag}>
                <I18n scope="provider_member_specialties.service_platforms.service_features" text="recruitable" />
              </span>
            )}
          </div>
        </label>
        <TextWithPopup
          text={
            showInfoIcon ? (
              <div className={styles.specialtyInfoIcon}>
                <InfoIcon fill="#4E89A4" width={16} height={16} />
              </div>
            ) : (
              <I18n scope={TRANSLATION_SCOPE} text="info" />
            )
          }
          popupTitle={speciality.displayName}
          popupDescription={<I18n scope={`${TRANSLATION_SCOPE}.specialties_descriptions`} text={speciality.code} />}
        />
      </div>
      {speciality.inputProps.checked && hasMultipleFeatures && (
        <div className={cx('specialty-features', styles.specialtyFeatures)}>
          {speciality.features.map(feature => {
            const displayName = translateServiceFeature(feature.code);
            return (
              <label className={styles.singleSpecialtyFeature} key={feature.code}>
                <input
                  {...feature.inputProps}
                  value={displayName}
                  type="radio"
                  onChange={() => onInputClick(speciality.code, feature.code)}
                />
                {displayName}
              </label>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FollowupSpecialties;
