import React from 'react';
import PropTypes from 'prop-types';

const RollUpIcon = ({ width = '20', height = '20', fill = '#fff' }) => (
  <svg
    version="{1.0}"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512.000000 612.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.300000,712.000000) scale(0.1200000,-0.100000)" fill={fill} stroke="none">
      <path
        d="M550 2748 c-55 -29 -95 -79 -112 -140 -24 -91 24 -190 112 -235 l44
-23 1966 0 1966 0 44 23 c88 45 136 144 112 235 -17 61 -57 111 -112 140 l-44
22 -1966 0 -1966 0 -44 -22z"
      />
    </g>
  </svg>
);

RollUpIcon.propTypes = {
  fill:   PropTypes.string,
  width:  PropTypes.string,
  height: PropTypes.string
};

export default RollUpIcon;
