import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';
import styles from './followup-specialties.module.scss';
import { FollowupSpecialtiesItem, mapSpecialty } from './followup-specialties';

const FollowupSpecialtiesPlatform = ({ platform, isServiceSelected, onServiceClick, translationScope }) => (
  <div
    data-test-specialties-group={platform.servicingPlatformCode}
    key={platform.servicingPlatformCode}
    className={styles.specialtiesGroup}
  >
    <div className={styles.specialtiesGroupTitle}>
      <I18n scope={translationScope} text={platform.servicingPlatformCode} />
    </div>
    <div className={styles.followupSpecialtiesItems}>
      {platform.specialties.map((specialty) => (
        <FollowupSpecialtiesItem
          key={specialty.code}
          speciality={mapSpecialty(specialty, isServiceSelected)}
          onInputClick={onServiceClick}
          showInfoIcon={!!platform.sesProvider}
        />
      ))}
    </div>
  </div>
);

FollowupSpecialtiesPlatform.propTypes = {
  className: PropTypes.string,
  platform:  PropTypes.shape({
    servicingPlatformCode: PropTypes.string.isRequired,
    sesProvider:           PropTypes.bool,
    specialties:           PropTypes.array.isRequired
  }).isRequired,
  isServiceSelected: PropTypes.func.isRequired,
  onServiceClick: PropTypes.func.isRequired,
  translationScope: PropTypes.string.isRequired
};

export default FollowupSpecialtiesPlatform;
