import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ISymptom from '../../interfaces/symptom-interface';
import ConfirmationDialog from '../shared/confirmation-dialog';
import removeIcon from '../../../assets/images/icon_notice_x_thumb.png';
import validation from '../../utilities/constants/validation';

const { symptomNote } = validation;

class SymptomListItem extends Component {
  state = {
    shouldHighlightItem: false,
    removeDialogVisible: false
  };

  componentDidMount() {
    if (this.props.shouldFocus) {
      this.focusItem();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.shouldFocus && this.props.shouldFocus) {
      this.focusItem();
    }
  }

  componentWillUnmount() {
    if (this.removeHighlightTimeout) {
      clearTimeout(this.removeHighlightTimeout);
    }
  }

  removeHighlightTimeout = null;

  focusItem() {
    this.scrollToItem();
    this.highlightItem();
  }

  scrollToItem() {
    const {
      onSymptomFocusClear,
      symptom: { code }
    } = this.props;

    onSymptomFocusClear(code);

    this.refs.listItemInput.focus({ preventScroll: true });
    this.refs.listItem.scrollIntoView({ behavior: 'smooth' });
  }

  highlightItem() {
    this.setState({
      shouldHighlightItem: true
    });

    this.removeHighlightTimeout = setTimeout(() => {
      this.setState({ shouldHighlightItem: false });
      this.removeHighlightTimeout = null;
    }, 2500);
  }

  unselectSymptom = () => {
    const { symptom, onSymptomUnselected } = this.props;

    onSymptomUnselected(symptom.code);
  };

  showRemoveDialog = () => {
    this.setState({ removeDialogVisible: true });
  };

  hideRemoveDialog = () => {
    this.setState({ removeDialogVisible: false });
  };

  onRadioChange = ({ target: { value } }) => {
    const {
      onRadioChange,
      symptom,
      listId
    } = this.props;

    onRadioChange(symptom.code, value, listId);
  }

  onNoteChange = ({ target: { value } }) => {
    const {
      onNoteChange,
      symptom
    } = this.props;

    onNoteChange(symptom.code, value);
  }

  render() {
    const {
      symptom,
      radioValue,
      noteValue,
      listId,
      shouldDisable,
      canRemove,
      notApplicableSymptomOptionHidden
    } = this.props;

    return (
      <div
        ref="listItem"
        className={`symptoms-list-item ${this.state.shouldHighlightItem ? 'highlight' : ''}`}
      >
        <div className="symptom-name-container">
          <span className="symptom-name-span">
            <span className="symptom-remove-container">
              {canRemove ? (
                <span className="symptom-remove-span">
                  <img
                    className="symptom-remove"
                    src={removeIcon}
                    alt="X"
                    onClick={this.showRemoveDialog}
                  />
                </span>
              ) : null}
            </span>
            <span className="symptom-name">{symptom.name}</span>
          </span>
        </div>
        {!notApplicableSymptomOptionHidden && (
          <div className="symptom-radio">
            <input
              type="radio"
              id={`symptom_${symptom.code}_na_${listId}`}
              checked={radioValue === 'n/a'}
              onChange={this.onRadioChange}
              disabled={shouldDisable}
              value="n/a"
            />
            <label htmlFor={`symptom_${symptom.code}_na_${listId}`}>n/a</label>
          </div>
        )}
        <div className="symptom-radio">
          <input
            type="radio"
            id={`symptom_${symptom.code}_no_${listId}`}
            checked={radioValue === 'no'}
            onChange={this.onRadioChange}
            disabled={shouldDisable}
            value="no"
          />
          <label htmlFor={`symptom_${symptom.code}_no_${listId}`}>No</label>
        </div>
        <div className="symptom-radio">
          <input
            type="radio"
            id={`symptom_${symptom.code}_yes_${listId}`}
            checked={radioValue === 'yes'}
            onChange={this.onRadioChange}
            disabled={shouldDisable}
            value="yes"
          />
          <label htmlFor={`symptom_${symptom.code}_yes_${listId}`}>Yes</label>
        </div>
        <div
          style={{ visibility: radioValue === 'n/a' ? 'hidden' : 'visible' }}
          className="symptom-note"
        >
          <div className="symptom-note-container">
            <input
              ref="listItemInput"
              type="text"
              id={`symptom_${symptom.code}_note`}
              placeholder="(optional note)"
              value={noteValue.slice(0, symptomNote.maxLength)}
              disabled={shouldDisable}
              onChange={this.onNoteChange}
              maxLength={symptomNote.maxLength}
            />
            { noteValue.length === symptomNote.maxLength ?
              <span className="max-len-span red symptom-note-limit-reached">
                ({noteValue.length} of {symptomNote.maxLength} characters max)
              </span> : null }
          </div>
        </div>
        <ConfirmationDialog
          text="Are you sure you want to remove this from the added symptoms?"
          visible={this.state.removeDialogVisible}
          onConfirm={this.unselectSymptom}
          onClose={this.hideRemoveDialog}
        />
      </div>
    );
  }
}

SymptomListItem.propTypes = {
  symptom:             ISymptom,
  radioValue:          PropTypes.string.isRequired,
  noteValue:           PropTypes.string.isRequired,
  onRadioChange:       PropTypes.func.isRequired,
  onNoteChange:        PropTypes.func.isRequired,
  listId:              PropTypes.string.isRequired,
  shouldDisable:       PropTypes.bool.isRequired,
  onSymptomUnselected: PropTypes.func.isRequired,
  shouldFocus:         PropTypes.bool.isRequired,
  onSymptomFocusClear: PropTypes.func.isRequired,
  canRemove:           PropTypes.bool.isRequired,
  notApplicableSymptomOptionHidden: PropTypes.bool.isRequired,
};

export default SymptomListItem;
