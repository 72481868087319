import React, { useState, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get, find, reject } from 'lodash';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { translate } from '@td/shared_utils';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import Loader from '../components/Loader';
import CustomCalendarEvent from './components/custom-calendar-event-v2';
import ConsultationRescheduleContainer from '../my-schedule/containers/consultation-reschedule-container';
import { DAY_NAMES } from '../my-schedule/constants';

import './calendar.scss';
import RecommendedWorkingHoursEvent from './components/recommended-working-hours-event';
import { eventTypeCodes, viewTypes } from './constants';

const localizer = momentLocalizer(moment);

const ProviderCalendar = ({
  apolloClient,
  scheduleTypes,
  onEditEvent,
  providerShifts,
  createRecommendedWorkingHours,
  canEditSchedule,
  loadSchedules,
  calendarIncrement,
  refetchQuery
}) => {
  /*
   * State
   */
  const [view, setView] = useState(viewTypes.DEFAULT_VIEW);
  const [showDetails, setShowDetails] = useState(null);
  const [activeModal, setActiveModal] = useState(null);
  const [datetimeRange, setDatetimeRange] = useState({
    startDate: moment().startOf('week'),
    endDate:   moment().endOf('week')
  });
  const [calendarDate, setCalendarDate] = useState(new Date());

  /*
   * Constants
   */
  const {
    loading,
    error,
    schedules: {
      formattedEvents,
      allATBEventsByDay,
      allDayPersonalEvents,
      sortedRegularWorkingHours
    }
  } = providerShifts;

  const isWorkingHoursBlockOrRecommendedInMonth = ({ typeCode = '' }) =>
    [
      scheduleTypes.PROVSCHEDEVENT_RH.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode
    ].includes(typeCode) ||
    (view === viewTypes.MONTH && typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS);

  const isSameDate = (date1, date2) => moment(date1).format('YYYY-MM-DD') === moment(date2).format('YYYY-MM-DD');

  const isDateInBlock = (slotDate, { startDateTime, endDateTime }) => {
    const startDate = moment(startDateTime);
    const endDate = moment(endDateTime);
    const slotMoment = moment(slotDate);

    return slotMoment.isSameOrAfter(startDate) && slotMoment.add(14, 'minute').isBefore(endDate);
  };

  const anyATBOverrideInDate = slotDate => {
    const slotDateString = moment(slotDate).format('D/MM/Y');
    const overrides = (slotDateString in allATBEventsByDay) && allATBEventsByDay[slotDateString].filter(
      ({ availabilityOverrideFlag }) => availabilityOverrideFlag
    );

    return overrides && overrides.some(({ start, end }) => isSameDate(start, slotDate) || isSameDate(end, slotDate));
  };

  const isWhiteWorkingHour = slotDate => {
    const formattedDate = moment(slotDate);
    const formattedDay = formattedDate.format('D/MM/Y');

    if (formattedDate.isBefore(Date.now())) return false;

    if (allDayPersonalEvents.includes(formattedDay)) return false;

    if (formattedDay in allATBEventsByDay &&
      allATBEventsByDay[formattedDay].some(atbEvent => isDateInBlock(formattedDate, atbEvent))) return true;

    const slotDayOfWeek = DAY_NAMES[formattedDate.day()];

    return (slotDayOfWeek in sortedRegularWorkingHours) && sortedRegularWorkingHours[slotDayOfWeek].some(rhEvent => {
      const slotInEvent = moment(rhEvent.start).set({
        hours:   formattedDate.hours(),
        minutes: formattedDate.minutes()
      });
      const nullRecurrence = !rhEvent.recurrenceRuleEndDt;
      const futureRecurrence =
        rhEvent.recurrenceRuleEndDt && moment(rhEvent.recurrenceRuleEndDt).isAfter(formattedDate);

      return isDateInBlock(slotInEvent, rhEvent) &&
        (nullRecurrence || futureRecurrence) &&
        !anyATBOverrideInDate(formattedDate);
    });
  };

  /*
   * Callbacks
   */
  const handleRescheduleModalClose = () => {
    refetchQuery();
    setActiveModal(null);
  };

  const onRangeChange = useCallback(calendarRange => {
    const calendarDatetimeRange = {
      startDate: moment(get(calendarRange, 0, calendarRange.start)).startOf('day'),
      endDate:   moment(get(calendarRange, 6, calendarRange.end)).endOf('day')
    };

    if (
      !datetimeRange.startDate.isBefore(calendarDatetimeRange.startDate) ||
      !datetimeRange.endDate.isAfter(calendarDatetimeRange.endDate)
    ) {
      setDatetimeRange({
        startDate: calendarDatetimeRange.startDate,
        endDate:   calendarDatetimeRange.endDate
      });
    }
  }, [datetimeRange, setDatetimeRange]);

  const onNavigate = useCallback(newDate => setCalendarDate(newDate), [setCalendarDate]);

  /*
   * Memoizing
   */
  const { defaultDate, calendarViews, formats, scrollToTime } = useMemo(
    () => ({
      defaultDate:   new Date(),
      calendarViews: [viewTypes.WEEK],
      formats:       {
        timeGutterFormat: date => (moment(date).minutes() === 0 ? moment(date).format('h:mm A') : '')
      },
      scrollToTime: moment().hour() > 5
        ? moment().startOf('hour')
          .add(Math.floor(moment().minutes() / 15) * 15, 'minutes')
          .subtract(4, 'hours')
          .toDate()
        : moment().startOf('day').toDate()
    }),
    []
  );

  /*
   * Effects
   */
  useEffect(() => {
    loadSchedules({ startDate: datetimeRange.startDate.toISOString(), endDate: datetimeRange.endDate.toISOString() });
  }, [datetimeRange]);

  /*
   * Rendering
   */
  if (loading) {
    return (
      <div style={{ minHeight: '700px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <div className="error">{translate(null, 'my_schedule', 'queries.error')}</div>;
  }

  return (
    <React.Fragment>
      <div className="providerCalendarV2">
        <Calendar
          defaultDate={defaultDate}
          scrollToTime={scrollToTime}
          views={calendarViews}
          view={view}
          onView={setView}
          showMultiDayTimes
          events={reject(formattedEvents, isWorkingHoursBlockOrRecommendedInMonth)}
          localizer={localizer}
          resizable
          timeslots={1}
          step={15}
          style={{ height: 700 }}
          apolloClient={apolloClient}
          slotPropGetter={date => ({ className: `${isWhiteWorkingHour(date) ? 'showAvailability' : ''} ${moment(date).minutes() === 45 ? 'end-of-hour' : ''}` })}
          formats={formats}
          date={calendarDate}
          onRangeChange={onRangeChange}
          onNavigate={onNavigate}
          eventPropGetter={event => {
            const canceledConsult = get(event, 'consultLcnsFlag', false);

            let eventTypeCode;

            if (canceledConsult) {
              eventTypeCode = scheduleTypes.PROVSCHEDEVENT_AWM_CAN.eventTypeCode;
            } else if (event.externalEvent) {
              eventTypeCode = scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT_EXTERNAL.eventTypeCode;
            } else {
              eventTypeCode = event.typeCode;
            }

            const eventProps = find(scheduleTypes, { eventTypeCode });
            const isFullTimeEvent = get(event, 'allDayEventFlg');

            const dynamicStyles = {
              backgroundColor: get(eventProps, 'background', 'initial'),
              border:          get(eventProps, 'border') ? `1px solid ${eventProps.border}` : 'none',
              color:           get(eventProps, 'color', 'initial')
            };

            const isPastDueEvent = moment(event.startDateTime).isBefore(Date.now());

            return {
              className: `custom-event-wrapper ${eventTypeCode.toLowerCase()}
                ${isPastDueEvent && 'past-due-event'} ${isFullTimeEvent && 'full-time-event-wrapper'}`,
              style: dynamicStyles
            };
          }}
          components={{
            // eslint-disable-next-line react/prop-types
            event: ({ event }) => {
              if (event.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS) {
                return (
                  <RecommendedWorkingHoursEvent
                    event={event}
                    createRecommendedWorkingHours={createRecommendedWorkingHours}
                    isProviderCalendarV2
                  />
                );
              }

              return (
                <CustomCalendarEvent
                  event={event}
                  setShowDetails={id => setShowDetails(id)}
                  showDetails={showDetails}
                  onEditEvent={editEvent => onEditEvent(editEvent)}
                  scheduleTypes={scheduleTypes}
                  setActiveModal={setActiveModal}
                  canEditSchedule={canEditSchedule}
                  calendarIncrement={calendarIncrement}
                />
              );
            }
          }}
        />
      </div>
      {activeModal && activeModal.modal === 'RESCHEDULE_MODAL' && activeModal.consultationId && activeModal.rescheduleProps && (
        <ConsultationRescheduleContainer
          consultationId={activeModal.consultationId}
          onModalClose={handleRescheduleModalClose}
          {...activeModal.rescheduleProps}
        />
      )}
    </React.Fragment>
  );
};

ProviderCalendar.propTypes = {
  apolloClient:  PropTypes.object.isRequired,
  scheduleTypes: PropTypes.shape({
    PROVSCHEDEVENT_AWM:           PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired,
    PROVSCHEDEVENT_PERSONALEVENT: PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired,
    PROVSCHEDEVENT_RH:            PropTypes.shape({ eventTypeCode: PropTypes.string.isRequired }).isRequired
  }).isRequired,
  onEditEvent:    PropTypes.func,
  providerShifts: PropTypes.shape({
    schedules: PropTypes.shape({
      formattedEvents:           PropTypes.array,
      allATBEventsByDay:         PropTypes.object,
      allDayPersonalEvents:      PropTypes.array,
      schedulesByType:           PropTypes.object,
      sortedRegularWorkingHours: PropTypes.object
    }),
    loading: PropTypes.bool,
    error:   PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
  }),
  createRecommendedWorkingHours: PropTypes.func.isRequired,
  canEditSchedule:               PropTypes.bool,
  loadSchedules:                 PropTypes.func.isRequired,
  calendarIncrement:             PropTypes.number,
  refetchQuery:                  PropTypes.func.isRequired
};

export default ProviderCalendar;
