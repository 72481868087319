import React from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';
import InfoboxSpecialties from './components/infobox-specialties';
import ConsultFollowupContainer from './components/consult-followup-container';
import ConsultFollowupTitle from './components/consult-followup-title';
import store from '../lib/store';
import { Provider } from 'react-redux';
import $ from 'jquery';

export const initializeInfoboxSpecialties = (rootSelector, apolloClient) => {
  const infoboxSpecialtiesContainer = document.querySelector(
    `${rootSelector} #react_patient_information_available_specialties`
  );
  if (infoboxSpecialtiesContainer) {
    const { memberId, stateCode } = infoboxSpecialtiesContainer.dataset;

    ReactDOM.render(
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <InfoboxSpecialties memberId={parseInt(memberId)} stateCode={stateCode} />
        </Provider>
      </ApolloProvider>,
      infoboxSpecialtiesContainer
    );
  }
};

export const initializeConsultFollowup = (rootSelector, apolloClient) => {
  const $container = $(`${rootSelector} #react_consult_form_followups`);
  if ($container.length) {
    const { memberId, consultSpecialtyName, stateCode, initialValues } = $('#consult_form').data();
    const {
      memberBrand,
      noFurtherCareMinDays,
      noFurtherCareMaxDays,
      allowScheduleFollowup,
      communicationMethod,
      memberHasPcp,
      continueTreatment,
      patientManagementToolsV2Enabled,
      isMentalHealth,
      terminateReasonCode,
      isMyleIntegrated
    } = $container.data();

    ReactDOM.render(
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <ConsultFollowupContainer
            memberId={parseInt(memberId)}
            consultSpecialtyName={consultSpecialtyName}
            providerScheduledAppointmentAllowed={allowScheduleFollowup}
            continueTreatment={continueTreatment}
            memberBrand={memberBrand}
            stateCode={stateCode}
            noFurtherCareMinDays={parseInt(noFurtherCareMinDays)}
            noFurtherCareMaxDays={parseInt(noFurtherCareMaxDays)}
            initialValues={initialValues}
            communicationMethod={communicationMethod}
            memberHasPcp={memberHasPcp}
            patientManagementToolsV2Enabled={patientManagementToolsV2Enabled}
            isMentalHealth={isMentalHealth}
            terminateReasonCode={terminateReasonCode}
            isMyleIntegrated={isMyleIntegrated}
          />
        </Provider>
      </ApolloProvider>,
      $container[0]
    );
  }
};

export const initializeConsultFollowupTitle = (rootSelector, apolloClient) => {
  const $container = $(`${rootSelector} #react_consult_follow_up_title`);
  if ($container.length) {
    const { continueTreatment } = $container.data();

    ReactDOM.render(
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <ConsultFollowupTitle continueTreatment={continueTreatment} />
        </Provider>
      </ApolloProvider>,
      $container[0]
    );
  }
};
