import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { map, concat, without } from 'lodash';
import moment from 'moment-timezone';
import { formatSelectedDate } from '@td/shared_utils';
import { I18n } from '@td/shared_utils';
import Calendar from '../../datepicker/datepicker';
import calendarIcon from '../../assets/images/calendar-icon.svg';
import calendarAddIcon from '../../assets/images/add-event.svg';
import './consultation-rescheduling-selection.scss';
import { SEND_MESSAGE_ACTION, PROPOSE_DATE_ACTION } from './consultation-rescheduling-modal-form';

const ConsultationReschedulingSelection = ({
  onClose,
  selectedAction,
  onSelectAction,
  canSubmit,
  onSubmit,
  timezone,
  onSelectDates,
  locale,
  onCheckTimeConflicts,
  conflictErrors,
  selectedDates,
  lockedSlots,
  memberRequestedDates,
  isWebsdkRescheduleToggle,
  reschedulingIntervals,
  isTimeSlotOnly,
  intervalBetweenScheduling
}) => {
  const [notes, setNotes] = useState(null);
  // Register notes input ref
  let notesInputRef = useRef(null);

  // Store selected / edited dates
  const handleConfirmDate = (date, editModeIndex) => {
    let newDates;
    if (editModeIndex) {
      selectedDates[editModeIndex] = date;
      newDates = selectedDates;
    } else {
      newDates = concat(selectedDates, date);
    }
    onSelectDates({ dates: newDates, currentConflictErrors: conflictErrors });
    onCheckTimeConflicts(date, 'summary', editModeIndex, conflictErrors);
  };

  // Remove specific date from selected dates list
  const removeDate = date => {
    const newDates = without(selectedDates, date);
    onSelectDates({ dates: newDates, dateToRemove: date, currentConflictErrors: conflictErrors });
  };

  // Set focus by clicking on custom input placeholder
  const handleInputFocus = () => {
    notesInputRef.focus();
  };

  // Custom handler element embeded to datepicker ( open handler element )
  const selectDateElement = () => {
    if (selectedDates.length) {
      return (
        <div className={`reschedulingOptionsAddAnotherDate ${!isWebsdkRescheduleToggle ? 'isToggleOff' : ''}`}>
          <span>
            <img className="calendarIcon" src={calendarAddIcon} style={{ marginRight: 5 }} />
          </span>
          <I18n scope="provider_reschedule.modal.dates.add_another" />
        </div>
      );
    }
    return (
      <div className={`reschedulingOptionsAddDate ${!isWebsdkRescheduleToggle ? 'isToggleOff' : ''}`}>
        <span>
          <img className="calendarIcon" src={calendarIcon} style={{ marginRight: 5 }} />
        </span>
        <I18n scope="provider_reschedule.modal.dates.add" />
      </div>
    );
  };

  // Custom handler element embeded to datepicker ( open handler element ) used for edit dates ONLY
  const editDateElement = () => (
    <a className="reschedulingOptionsItemButton">
      <I18n scope="provider_reschedule.modal.dates.edit" />
    </a>
  );

  const renderOptions = () => (
    <div className="reschedulingOptionsWrapper">
      <p id="reschedulingOptionsLabel">
        <I18n scope="provider_reschedule.modal.select_reschedule_action.label" />
      </p>
      <div role="radiogroup" aria-labelledby="reschedulingOptionsLabel">
        <label className="reschedulingOption" onChange={onSelectAction.bind(this, SEND_MESSAGE_ACTION)}>
          <input
            type="radio"
            name="reschedulingOption"
            value={SEND_MESSAGE_ACTION}
            checked={selectedAction === SEND_MESSAGE_ACTION}
          />
          <span className="reschedulingOptionLabel">
            <I18n scope="provider_reschedule.modal.select_reschedule_action.ask_member" />
          </span>
        </label>
        {!isTimeSlotOnly && (
          <label className="reschedulingOption" onChange={onSelectAction.bind(this, PROPOSE_DATE_ACTION)}>
            <input
              type="radio"
              name="reschedulingOption"
              value={PROPOSE_DATE_ACTION}
              checked={selectedAction === PROPOSE_DATE_ACTION}
            />
            <span className="reschedulingOptionLabel">
              <I18n scope="provider_reschedule.modal.select_reschedule_action.choose_date" />
            </span>
          </label>
        )}
      </div>
    </div>
  );

  // Basic default calendar settings (not required)
  const currentYear = new Date().getFullYear();
  const lastVisibleYear = new Date().setYear(currentYear + 3);

  const hasRequestedDates = memberRequestedDates && memberRequestedDates.length > 0;
  const reschedulingIntervalsParsed = reschedulingIntervals ? JSON.parse(reschedulingIntervals) : {};

  return (
    <div className="reschedulingModalBodyWrapper">
      {hasRequestedDates && !isTimeSlotOnly && (
        <div className="rescheduleMemberAvailabilityWrapper">
          <div className="rescheduleMemberAvailabilityLabel">Patient Availability:</div>
          <div className="rescheduleMemberAvailabilityItems">
            {map(memberRequestedDates, (memberRequestedDate, index) => (
              <div className="rescheduleMemberAvailabilityItem" key={index}>
                {memberRequestedDate}
                {index === 0 && <span className="rescheduleMemberAvailabilityPreferred">Preferred</span>}
              </div>
            ))}
          </div>
        </div>
      )}

      {(isWebsdkRescheduleToggle || isTimeSlotOnly) && renderOptions()}

      {(selectedAction === PROPOSE_DATE_ACTION || (!isWebsdkRescheduleToggle && !isTimeSlotOnly)) && (
        <div className="reschedulingOptionsItems">
          {map(selectedDates, (selectedDate, index) => {
            const selectedDateTZ = moment(selectedDate)
              .tz(moment.tz.guess())
              .format('zz');
            return (
              <div
                className={`reschedulingOptionsItem ${
                  index === selectedDates.length - 1 ? 'dividerTopBottom' : 'divider'
                }`}
                key={index}
              >
                <span className={`reschedulingOptionsItemDate ${conflictErrors[index] ? 'error' : ''}`}>
                  {formatSelectedDate(selectedDate, selectedDateTZ, locale)}
                </span>
                <Calendar
                  timezone={timezone}
                  locale={locale}
                  timeIntervals={intervalBetweenScheduling}
                  selectedDate={selectedDate}
                  onConfirmDate={handleConfirmDate}
                  customInputComponent={editDateElement()}
                  editModeIndex={index.toString()}
                  lockedSlots={lockedSlots}
                  maxDate={reschedulingIntervalsParsed.scheduling_time_span_days}
                  minTime={reschedulingIntervalsParsed.min_time}
                  maxTime={reschedulingIntervalsParsed.max_time}
                />
                <a className="reschedulingOptionsItemButton removeDate" onClick={removeDate.bind(this, selectedDate)}>
                  <I18n scope="provider_reschedule.modal.dates.remove" />
                </a>
                {(conflictErrors[index] ? 'error' : '') && (
                  <div className="reschedulingOptionsItemErrorMessage">
                    <I18n scope="provider_reschedule.modal.dates.conflict" />
                  </div>
                )}
              </div>
            );
          })}
          <Calendar
            timezone={timezone}
            locale={locale}
            timeIntervals={intervalBetweenScheduling}
            selectedDate={new Date()}
            onConfirmDate={handleConfirmDate}
            customInputComponent={selectDateElement()}
            lockedSlots={lockedSlots}
            maxDate={reschedulingIntervalsParsed.scheduling_time_span_days}
            minTime={reschedulingIntervalsParsed.min_time}
            maxTime={reschedulingIntervalsParsed.max_time}
          />
        </div>
      )}

      {!isWebsdkRescheduleToggle && !isTimeSlotOnly && (!selectedDates || (selectedDates && !selectedDates.length)) && (
        <div className="noDatesSelected">No proposed time(s) selected.</div>
      )}

      <div className="reschedulingNotesWrapper">
        <label className="reschedulingNotesLabel" for="reschedulingNoteInput">
          <I18n scope="provider_reschedule.modal.message.label" />
        </label>
        <div className="reschedulingNote">
          {!notes && (
            <label className="placeholder" onClick={handleInputFocus.bind(this)} for="reschedulingNoteInput">
              <I18n scope="provider_reschedule.modal.message.placeholder" />
            </label>
          )}
          <input
            type="textarea"
            name="reschedulingNote"
            id="reschedulingNoteInput"
            ref={r => (notesInputRef = r)}
            onChange={e => setNotes(e.target.value)}
          />
        </div>
      </div>

      <div className="actions">
        <button
          className="button reschedulingConfirm"
          disabled={!canSubmit}
          onClick={canSubmit ? onSubmit.bind(this, selectedDates, notes) : null}
        >
          <I18n scope="provider_reschedule.modal.actions.submit" />
        </button>
        <button className="button reschedulingDismiss" onClick={onClose.bind(this)}>
          <I18n scope="provider_reschedule.modal.actions.cancel" />
        </button>
      </div>
    </div>
  );
};

ConsultationReschedulingSelection.propTypes = {
  selectedAction:           PropTypes.string,
  timezone:                 PropTypes.string,
  locale:                   PropTypes.string,
  canSubmit:                PropTypes.bool,
  onClose:                  PropTypes.func.isRequired,
  onSelectAction:           PropTypes.func.isRequired,
  onSubmit:                 PropTypes.func.isRequired,
  onSelectDates:            PropTypes.func,
  onCheckTimeConflicts:     PropTypes.func,
  conflictErrors:           PropTypes.array,
  lockedSlots:              PropTypes.array,
  isWebsdkRescheduleToggle: PropTypes.bool,
  reschedulingIntervals:    PropTypes.string,
  isTimeSlotOnly:           PropTypes.bool
};

export default ConsultationReschedulingSelection;
