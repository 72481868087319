import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'app/components/Loader';
import { I18n } from '@td/shared_utils';
import { connect } from 'react-redux';
import styles from './consult-followup.module.scss';
import TextWithPopup from './text-with-popup';
import {
  groupSpecialties,
  filteredSpecialties,
  fetchMemberData,
  groupSesProviderSpecialties,
  getSesMemberSpecialtiesConfig
} from '../helpers';
import { fetchMemberSpecialties } from '../state-managment/actions';

export const TRANSLATION_SCOPE = 'provider_member_specialties';

export const InfoboxSpecialties = ({ memberId, stateCode, services, servicesLoading, servicesError, fetchSpecialties }) => {
  useEffect(() => {
    fetchSpecialties({ id: memberId, ...fetchMemberData(), stateCode });
  }, [fetchSpecialties]);

  const renderSpecialties = () => {
    const filteredServiceSpecialties = filteredSpecialties(services) || [];
    const platformsWithSpecialties = getSesMemberSpecialtiesConfig().enabled
      ? groupSesProviderSpecialties(filteredServiceSpecialties)
      : groupSpecialties(filteredServiceSpecialties);

    return platformsWithSpecialties.map((platform) => (
      <div key={platform.servicingPlatformCode} className={styles.specialtiesGroup}>
        <div className={styles.specialtiesGroupTitle}>
          <I18n scope={`${TRANSLATION_SCOPE}.service_platforms`} text={platform.servicingPlatformCode} />
        </div>
        <div>
          {platform.specialties.map((spec, index) => (
            <div key={index}>
              <TextWithPopup
                text={spec.displayName}
                popupTitle={spec.displayName}
                popupDescription={<I18n scope={`${TRANSLATION_SCOPE}.specialties_descriptions`} text={spec.code} />}
              />
            </div>
          ))}
        </div>
      </div>
    ));
  };

  const renderContent = () => {
    if (servicesError) return <I18n scope={TRANSLATION_SCOPE} text="failed_to_load" />;
    if (servicesLoading) return <Loader />;
    return renderSpecialties();
  };

  return (
    <li className={styles.infoboxSpecialtiesRoot}>
      <strong>
        <I18n scope={TRANSLATION_SCOPE} text="title" />
      </strong>
      <div>{renderContent()}</div>
    </li>
  );
};

InfoboxSpecialties.propTypes = {
  memberId: PropTypes.number.isRequired,
  fetchSpecialties: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  servicesLoading: PropTypes.bool.isRequired,
  servicesError: PropTypes.string,
};

const InfoboxSpecialtiesConnected = connect(
  (state) => ({
    services: state.consultFollowup.teladocServices,
    servicesLoading: state.consultFollowup.teladocServicesLoading,
    servicesError: state.consultFollowup.teladocServicesError,
  }),
  (dispatch) => ({
    fetchSpecialties: (member) => dispatch(fetchMemberSpecialties(member)),
  }),
)(InfoboxSpecialties);

export default InfoboxSpecialtiesConnected;
