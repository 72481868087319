import React from 'react';
import PropTypes from 'prop-types';

const AssistantChatIcon = ({ action, className, fill = '#F09D02' }) => (
  <svg className={className} width="176px" height="162px" viewBox="0 0 176 162" version="1.1">
    <title>Health Assistant Closed</title>
    <defs>
      <filter x="-107.1%" y="-142.9%" width="314.3%" height="385.7%" filterUnits="objectBoundingBox" id="filter-1">
        <feGaussianBlur stdDeviation="20" in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Symbols" onClick={action} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Health-Assistant-Closed" transform="translate(48.000000, 15.000000)">
        <g id="Group" transform="translate(0.000000, 33.000000)" fill="#000000" fillOpacity="0.198126093">
          <g id="Card-/-shadow">
            <rect id="Fill" filter="url(#filter-1)" x="12" y="12" width="56" height="42" />
          </g>
        </g>
        <circle id="Oval" fill={fill} cx="40" cy="40" r="40" />
        <g id="f-comment" transform="translate(19.000000, 22.000000)" fill="#FFFFFF">
          <path
            d="M21.3333333,0 C33.1702729,0 42.6666667,7.52709556 42.6666667,17.3333333 C42.6666667,21.3718902 41.0520823,25.2234778 38.1786637,28.2393099 L38.1786637,28.2393099 L38.0393333,28.3803333 L38.6633727,37.2396686 C38.7299424,38.1849588 37.8228164,38.8820608 36.941926,38.6081809 L36.941926,38.6081809 L36.8272582,38.5668915 L26.8123333,34.4573333 L26.5729828,34.5105319 C25.0358464,34.8293277 23.5197636,35.0160215 21.9899231,35.0577138 L21.9899231,35.0577138 L21.3333333,35.0666667 C9.58549033,35.0666667 1.49213975e-13,27.2039142 1.49213975e-13,17.3333333 C1.49213975e-13,7.52709556 9.49639381,0 21.3333333,0 Z M21.3333333,2.66666667 C10.850893,2.66666667 2.66666667,9.15370321 2.66666667,17.3333333 C2.66666667,25.6016157 10.9545846,32.4 21.3333333,32.4 C23.0937616,32.4 24.8366681,32.1821367 26.6335207,31.7674784 C26.9024676,31.7054137 27.1840477,31.7283451 27.4394085,31.8331085 L27.4394085,31.8331085 L35.8513333,35.2833333 L35.3366273,27.9603314 C35.3122211,27.6137635 35.4240779,27.2734551 35.6448347,27.0101278 L35.6448347,27.0101278 L35.7460323,26.9021926 C38.4727985,24.2993703 40,20.8949837 40,17.3333333 C40,9.15370321 31.8157737,2.66666667 21.3333333,2.66666667 Z M22.6666667,20 C23.4030463,20 24,20.5969537 24,21.3333333 C24,22.0263965 23.4712106,22.5959544 22.7950756,22.660563 L22.6666667,22.6666667 L12,22.6666667 C11.2636203,22.6666667 10.6666667,22.069713 10.6666667,21.3333333 C10.6666667,20.6402701 11.1954561,20.0707123 11.8715911,20.0061036 L12,20 L22.6666667,20 Z M30.6666667,12 C31.4030463,12 32,12.5969537 32,13.3333333 C32,14.0263965 31.4712106,14.5959544 30.7950756,14.660563 L30.6666667,14.6666667 L12,14.6666667 C11.2636203,14.6666667 10.6666667,14.069713 10.6666667,13.3333333 C10.6666667,12.6402701 11.1954561,12.0707123 11.8715911,12.0061036 L12,12 L30.6666667,12 Z"
            id="Combined-Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

AssistantChatIcon.propTypes = {
  action:    PropTypes.func,
  className: PropTypes.string
};

export default AssistantChatIcon;
