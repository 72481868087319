import {
  FETCH_MEMBER_SPECIALTIES,
  FETCH_MEMBER_SPECIALTIES_FAILED,
  FETCH_MEMBER_SPECIALTIES_FULFILLED,
  FETCH_MEMBER_SPECIALTIES_STARTED,
} from './action-types';

export const fetchMemberSpecialties = (member) => ({ type: FETCH_MEMBER_SPECIALTIES, payload: member });
export const fetchMemberSpecialtiesStarted = () => ({ type: FETCH_MEMBER_SPECIALTIES_STARTED });
export const fetchMemberSpecialtiesFulfilled = (payload) => ({ type: FETCH_MEMBER_SPECIALTIES_FULFILLED, payload });
export const fetchMemberSpecialtiesFailed = (payload) => ({ type: FETCH_MEMBER_SPECIALTIES_FAILED, payload });
