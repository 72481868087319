import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { map, has, get, flattenDeep, filter, isEmpty } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { translate } from '@td/shared_utils';

// Queries / Mutations
import PROVIDER_CALENDAR_DATA_QUERY from '../queries/provider-calendar-data.graphql';
import PROVIDER_SCHEDULES_QUERY from '../queries/provider-profile-schedules.graphql';
import CREATE_SHIFT_EVENT from '../mutations/create-provider-schedule-event.graphql';
import UPDATE_SHIFT_EVENT from '../mutations/update-provider-schedule-event.graphql';
import DELETE_SHIFT_EVENT from '../mutations/delete-provider-schedule-event.graphql';

// Components, constants and helpers
import { MyScheduleContent, AvailabilitySettings, PersonalEventModal } from '../index';
import Notification from '../components/notification';
import { scheduleTypes, MyScheduleViews, MyScheduleModals, DAY_NAMES } from '../constants';
import * as stateInit from '../../calendar/services/state-initialization';
import { isSettingsViewRequested, separateDatesAndTimes } from '../helpers';
import saveShiftV2 from '../services/save-shift-v2';
import { eventTypeCodes, workHourTypeCodes } from '../../calendar/constants';
import { useRecommendedWorkingHours } from '../../recommended-working-hours/contexts/recommended-working-hours-context';
import { formatSingleEvent } from '../../calendar/services/get-formatted-events-v2';
import { useProviderEligibility } from '../features/external-calendar/contexts/provider-eligibility-context';

const TRANSLATION_SCOPE = 'my_schedule';

const DEFAULT_PROVIDER_SHIFTS = {
  loading:   true,
  error:     null,
  schedules: {
    formattedEvents:           [],
    allATBEventsByDay:         {},
    allDayPersonalEvents:      [],
    schedulesByType:           {},
    sortedRegularWorkingHours: {}
  }
};

const MyScheduleContainer = ({ providerId, apolloClient, canEditSchedule = true }) => {
  /*
   * States
   */
  const [currentView, setCurrentView] = useState(isSettingsViewRequested() ? MyScheduleViews.SETTINGS : MyScheduleViews.DEFAULT);
  const [isRegularWorkingHoursInitiated, setIsRegularWorkingHoursInitiated] = useState(window.location.hash === '#active=regular_working_hours');
  const [activeModal, setActiveModal] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [notification, setNotification] = useState(null);
  const [validateProps, setValidateProps] = useState(stateInit.validationProps);
  const [validatePropsMessages, setValidatePropsMessages] = useState(stateInit.validationPropsMessages);
  const [providerShifts, setProviderShifts] = useState(DEFAULT_PROVIDER_SHIFTS);
  const [customBuffer, setCustomBuffer] = useState(false);
  const [calendarIncrement, setCalendarIncrement] = useState(0);

  const {
    recommendedWorkingHours,
    refreshRecommendedWorkingHours,
    recommendedWorkingHoursFlag
  } = useRecommendedWorkingHours();

  const { isProviderEligibleForExternalCalendars } = useProviderEligibility();

  const recommendedWHLastFetchTime = useRef(null);

  /*
   * Constants
   */
  const initialWorkingHours = {
    startDateTime: moment()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0),
    endDateTime: moment()
      .set('hour', 17)
      .set('minute', 0)
      .set('second', 0)
  };

  const isActiveRegularWorkingHour = ({ eventTypeCode, recurrenceRuleEndDt }) =>
    eventTypeCode === scheduleTypes.PROVSCHEDEVENT_RH.eventTypeCode &&
    (!recurrenceRuleEndDt || moment(recurrenceRuleEndDt).isAfter(Date.now()));

  const isAcceptedBlock = ({ eventTypeCode = '' }) =>
    [
      scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_RH.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_AWM.eventTypeCode,
      scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode
    ].includes(eventTypeCode);

  const isAllDayPersonalEvent = ({ allDayEventFlg, eventTypeCode }) =>
    eventTypeCode === scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode && allDayEventFlg;

  const getSchedulesByType = useCallback((eventTypeCode) => {
    return providerShifts.schedules.schedulesByType[eventTypeCode] || [];
  }, [providerShifts]);

  const addRecommendedWorkingHoursToSchedules = ({ rwhFlag = false, rwhBlocks = [], providerSchedules = {} }) => {
    const { formattedEvents = [] } = providerSchedules;
    const filteredEvents = formattedEvents.filter(
      ({ typeCode }) => typeCode !== eventTypeCodes.RECOMMENDED_WORKING_HOURS
    );

    return {
      ...providerSchedules,
      formattedEvents: rwhFlag && rwhBlocks.length > 0 ? [...filteredEvents, ...rwhBlocks] : filteredEvents
    };
  };

  const processProviderShifts = data => {
    const formattedEvents = [];
    const allATBEventsByDay = {};
    const allDayPersonalEvents = [];
    const schedulesByType = {};
    const sortedRegularWorkingHours = {};

    if (data && has(data, 'providerProfile.schedules')) {
      const regularWorkingHoursByWeekdays = DAY_NAMES.reduce((acc, day) => ({ ...acc, [day]: {} }), {});

      const schedules = get(data, 'providerProfile.schedules', []);
      schedules.sort((a, b) => moment(a.startDateTime) - moment(b.startDateTime));
      map(schedules, schedule => {
        const { eventTypeCode, startDateTime, endDateTime, providerScheduleId, eventName, bufferTime } = schedule;

        const eventStart = moment(startDateTime);
        const startDateString = eventStart.format('D/MM/Y');
        const eventEnd = moment(endDateTime).subtract(
          eventTypeCode === scheduleTypes.PROVSCHEDEVENT_AWM.eventTypeCode && bufferTime > 0 ? bufferTime : 0,
          'minutes'
        );
        const eventDay = DAY_NAMES[eventStart.day()];

        const event = {
          ...schedule,
          id:            parseInt(providerScheduleId, 10),
          start:         eventStart.toDate(),
          end:           eventEnd.toDate(),
          title:         eventName,
          typeCode:      eventTypeCode,
          eventDuration: moment.duration(eventEnd.diff(eventStart)),
          startDateTime: eventStart,
          endDateTime:   eventEnd,
          externalEvent: isProviderEligibleForExternalCalendars && schedule.externalEvent ? {
            email: schedule.externalEvent.calendar.account.emailAddress,
            isExpired: schedule.externalEvent.calendar.account.expired
          } : null
        };
        const formattedEvent = formatSingleEvent(event);

        schedulesByType[eventTypeCode] = [...(schedulesByType[eventTypeCode] || []), event];

        if (isAcceptedBlock(event)) {
          formattedEvents.push(...formattedEvent);
        }

        if (eventTypeCode === scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode) {
          const startDates = [startDateString].concat(formattedEvent.length > 1 ? [eventEnd.format('D/MM/Y')] : []);
          startDates.forEach(stDTtring => {
            allATBEventsByDay[stDTtring] = [...(allATBEventsByDay[stDTtring] || []), event];
          });
        }

        if (isAllDayPersonalEvent(event)) {
          allDayPersonalEvents.push(...formattedEvent.map(({ start }) => moment(start).format('D/MM/Y')));
        }

        if (isActiveRegularWorkingHour(schedule)) {
          const startEndString = eventStart.format('HHmm') + eventEnd.format('HHmm');
          regularWorkingHoursByWeekdays[eventDay][startEndString] = event;
        }
      });

      Object.assign(sortedRegularWorkingHours, Object.fromEntries(
        Object.entries(regularWorkingHoursByWeekdays).map(([weekDay, weekDayObj]) => {
          const sortedArray = Object.values(weekDayObj)
            .sort((a, b) => a.startDateTime.hour() - b.startDateTime.hour());
          return [weekDay, sortedArray];
        })
      ));
    }

    return {
      formattedEvents,
      allATBEventsByDay,
      allDayPersonalEvents,
      schedulesByType,
      sortedRegularWorkingHours
    };
  };

  const processQueryError = ({
    message = translate(null, TRANSLATION_SCOPE, 'queries.error'),
    providerDataError = false
  }) => {
    setNotification({ type: 'error', message });
    setProviderShifts(prevProviderShifts => ({
      ...prevProviderShifts,
      error: message,
      ...(providerDataError ? {} : {
        loading:   false,
        schedules: DEFAULT_PROVIDER_SHIFTS.schedules
      })
    }));
  };

  const parseValidationProps = ({ providerProfile: { appointmentTimeBlockRules } }) => {
    let parsedRules = {};
    try {
      parsedRules = JSON.parse(appointmentTimeBlockRules) || {};
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to parse appointmentTimeBlockRules:', error);
    }

    const {
      min_atb_length_size: minShiftLengthSize,
      max_atb_length_size: maxShiftLengthSize,
      atb_minutes_time_step: shiftTimeStep,
      atb_minutes_preselected_duration: shiftPreselectedDuration,
      atb_minutes_time_buffer: minStartDate
    } = parsedRules;

    const validationPropsMessages = {
      startDateInPast:        translate(null, 'provider_calendar.validation', 'start_date_in_past'),
      endDateBeforeStartDate: translate(null, 'provider_calendar.validation', 'end_date_before_start_date'),
      endTimeBeforeStartTime: translate(null, 'provider_calendar.validation', 'end_time_before_start_time'),
      minShiftLength:         translate(null, 'provider_calendar.validation', 'min_shift_length', {
        min_atb_length_size: minShiftLengthSize
      }),
      maxShiftLength: translate(null, 'provider_calendar.validation', 'max_shift_length', {
        max_atb_length_size: maxShiftLengthSize / 60
      }),
      overlappingShift: translate(null, 'provider_calendar.validation', 'overlapping_shift'),
      minBufferTime:    translate(null, 'provider_calendar.validation', 'atb_minutes_time_buffer', {
        atb_minutes_time_buffer: minStartDate / 60
      })
    };

    return {
      validationProps: {
        minShiftLengthSize,
        maxShiftLengthSize,
        shiftTimeStep,
        shiftPreselectedDuration,
        minStartDate
      },
      validationPropsMessages
    };
  };

  const fetchRecommendedWorkingHours = () => {
    if (!recommendedWHLastFetchTime.current && recommendedWorkingHoursFlag) {
      refreshRecommendedWorkingHours();
      recommendedWHLastFetchTime.current = Date.now();
    }
  };

  const processQueryResponse = response => {
    const schedules = processProviderShifts(response);
    setProviderShifts({
      loading:   false,
      error:     null,
      schedules: addRecommendedWorkingHoursToSchedules({
        rwhFlag:           recommendedWorkingHoursFlag,
        rwhBlocks:         recommendedWorkingHours,
        providerSchedules: schedules
      })
    });

    fetchRecommendedWorkingHours();
  };

  const processProviderData = response => {
    if (!['appointmentTimeBlockRules', 'customBufferTime', 'calendarV2Increment'].every(
      prop => has(response, ['providerProfile', prop])
    )) {
      return processQueryError({ providerDataError: true });
    }

    const { validationProps, validationPropsMessages } = parseValidationProps(response);
    setValidateProps(validationProps);
    setValidatePropsMessages(validationPropsMessages);

    const customBufferTime = get(response, 'providerProfile.customBufferTime');
    setCustomBuffer(Number.isInteger(customBufferTime) ? customBufferTime : false);

    const calendarV2Increment = get(response, 'providerProfile.calendarV2Increment', 0);
    setCalendarIncrement(calendarV2Increment);
  };

  /*
   * Queries / mutations
   */
  const { loading: loadingProviderData } = useQuery(PROVIDER_CALENDAR_DATA_QUERY, {
    variables:   { providerId },
    onError:     ({ message }) => processQueryError({ message, providerDataError: true }),
    onCompleted: processProviderData
  });

  const { loading, refetch: refetchEvents } = useQuery(PROVIDER_SCHEDULES_QUERY, {
    fetchPolicy: 'network-only',
    variables:   {
      providerId,
      datetimeRange: {
        startDate: moment().startOf('week').toISOString(),
        endDate:   moment().endOf('week').toISOString()
      }
    },
    onError:     processQueryError,
    onCompleted: processQueryResponse
  });

  const handleRefetch = (variables = {}) => {
    const refetchFn = isEmpty(variables)
      ? () => refetchEvents()
      : () => refetchEvents(variables);

    refetchFn()
      .then(response => processQueryResponse(response.data))
      .catch(error => processQueryError(error));
  };

  const loadSchedules = useCallback(({ startDate, endDate }) => {
    handleRefetch({
      providerId,
      datetimeRange: {
        startDate,
        endDate
      }
    });
  }, [handleRefetch]);

  const handleMutationError = mutationError => {
    const defaultErrorMsg = translate(null, TRANSLATION_SCOPE, 'mutations.error');
    const errorMsg = get(flattenDeep([mutationError]), 0, defaultErrorMsg);
    setNotification({ type: 'error', message: typeof errorMsg === 'string' || errorMsg instanceof String ? errorMsg : defaultErrorMsg });
  };

  const handleMutationSuccess = (mutationType, warnings = []) => {
    const successType = mutationType ? `${mutationType}_success` : 'success';
    setNotification({
      type:    warnings.length === 0 ? 'success' : 'warning',
      message: translate(null, TRANSLATION_SCOPE, `mutations.${successType}`),
      warnings
    });
    handleRefetch();
  };

  const [callCreateShift, { loading: createShiftLoading }] = useMutation(CREATE_SHIFT_EVENT, {
    onCompleted: ({ tasCreateProviderScheduleEvent: { success, errors, warnings } }) => {
      if (success) {
        handleMutationSuccess('create', warnings);
      } else {
        handleMutationError(errors);
      }
    },
    onError: mutationError => handleMutationError(mutationError)
  });

  const [callUpdateShift, { loading: updateShiftLoading }] = useMutation(UPDATE_SHIFT_EVENT, {
    onCompleted: ({ tasUpdateProviderScheduleEvent: { success, errors, warnings } }) => {
      if (success) {
        handleMutationSuccess('update', warnings);
      } else {
        handleMutationError(errors);
      }
    },
    onError: mutationError => handleMutationError(mutationError)
  });

  const [callDeleteShift, { loading: deleteShiftLoading }] = useMutation(DELETE_SHIFT_EVENT, {
    onCompleted: ({ tasDeleteProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        handleMutationSuccess('delete');
      } else {
        handleMutationError(errors);
      }
    },
    onError: mutationError => handleMutationError(mutationError)
  });

  /*
   * Callbacks
   */
  const resetNotifications = () => {
    setNotification(null);
  };

  const handleOpenSettings = () => {
    setCurrentView(MyScheduleViews.SETTINGS);
  };

  const handleGoBack = () => {
    setCurrentView(MyScheduleViews.DEFAULT);
    resetNotifications();
  };

  const handleAddEditEvent = event => {
    if (!event || event.typeCode === scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode) {
      setActiveModal({
        type: MyScheduleModals.PERSONAL_EVENTS,
        event
      });
    }
    resetNotifications();
  };

  const resetModalSubmit = () => {
    setActiveModal(null);
    setSubmitting(false);
    resetNotifications();
  };

  const handleSubmit = ({ type, action, event, submitData }) => {
    if (loading || createShiftLoading || updateShiftLoading || deleteShiftLoading || loadingProviderData) return;
    resetModalSubmit();

    if (!type || !(type.id === MyScheduleModals.REGULAR_WORKING_HOURS.id && action !== 'delete' ? submitData : event)) {
      return setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
    }

    if (type.id === MyScheduleModals.REGULAR_WORKING_HOURS.id) {
      if (action === 'delete') {
        map(event, ({ providerScheduleId }) => {
          if (providerScheduleId) {
            callDeleteShift({ variables: { provider_schedule_id: providerScheduleId } });
          } else {
            setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
          }
        });
      } else {
        if (!has(submitData, 'success') || !has(submitData, 'error') || !has(submitData, 'errorList')) {
          return setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
        }

        const { success, error: submitError, errorList } = submitData;

        const submitNotification = {
          type:    success && !submitError ? 'success' : 'error',
          message: success && !submitError ? translate(null, TRANSLATION_SCOPE, 'mutations.success') : errorList[0]
        };

        setNotification(submitNotification);
        handleRefetch();
      }

      return;
    }

    if (action === 'delete') {
      const { providerScheduleId } = event;

      if (providerScheduleId) {
        callDeleteShift({ variables: { provider_schedule_id: providerScheduleId } });
      } else {
        setNotification({ type: 'error', message: translate(null, TRANSLATION_SCOPE, 'mutations.error') });
      }
    } else {
      saveShiftV2({
        formState:            separateDatesAndTimes(event),
        selectedEvent:        event,
        validateProps,
        validatePropsMessages,
        setInterfaceError:    handleMutationError,
        callCreateShift,
        callUpdateShift,
        isProviderCalendarV2: true,
        setModalErrorMessage: () => {}
      });
    }
  };

  const handleSavePersonalEvent = saveEventData => {
    setSubmitting(true);
    handleSubmit({ ...activeModal, event: { ...activeModal.event, ...saveEventData } });
  };

  const handleDeletePersonalEvent = () => {
    setSubmitting(true);
    handleSubmit({ ...activeModal, action: 'delete', event: { ...activeModal.event } });
  };

  const handleResetInit = () => {
    setIsRegularWorkingHoursInitiated(false);
  };

  /*
   * Effects
   */
  useEffect(() => {
    let timeout;
    if (notification !== null && notification.message !== translate(null, TRANSLATION_SCOPE, 'queries.error')) {
      timeout = setTimeout(() => {
        resetModalSubmit();
        resetNotifications();
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [notification]);

  useEffect(() => {
    setProviderShifts(prevProviderShifts => ({
      ...prevProviderShifts,
      schedules: addRecommendedWorkingHoursToSchedules({
        rwhFlag:           recommendedWorkingHoursFlag,
        rwhBlocks:         recommendedWorkingHours,
        providerSchedules: get(prevProviderShifts, 'schedules')
      })
    }));
  }, [recommendedWorkingHours, recommendedWorkingHoursFlag]);

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      handleRefetch();
    }, moment.duration(5, 'minutes').asMilliseconds());

    return () => clearInterval(refetchInterval);
  }, []);

  const createRecommendedWorkingHours = ({ start, end }) => {
    callCreateShift({
      variables: {
        provider_schedule_input_params: {
          eventName:      translate(null, 'my_schedule.events.recommended_working_hours', 'reason'),
          eventTypeCode:  eventTypeCodes.APPOINTMENT_TIME_BLOCK,
          startDateTime:  moment(start).toDate(),
          endDateTime:    moment(end).toDate(),
          timezoneCode:   moment.tz.guess(),
          workHourTypeCd: workHourTypeCodes.RECOMMENDED
        }
      }
    });
  };

  const personalEvents = useMemo(() => {
    return filter(
      getSchedulesByType(scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode),
      (schedule) => !schedule.externalEvent
    );
  }, [getSchedulesByType]);

  /*
   * Render
   */
  if (isRegularWorkingHoursInitiated || currentView === MyScheduleViews.SETTINGS) {
    return (
      <AvailabilitySettings
        providerId={providerId}
        notification={notification}
        setNotification={setNotification}
        regularWorkingHours={providerShifts.schedules.sortedRegularWorkingHours}
        specialWorkingHours={getSchedulesByType(scheduleTypes.PROVSCHEDEVENT_ATB.eventTypeCode)}
        personalEvents={personalEvents}
        onGoBack={handleGoBack}
        onSubmit={handleSubmit}
        resetNotifications={resetNotifications}
        isRegularWorkingHoursInitiated={isRegularWorkingHoursInitiated}
        resetInit={handleResetInit}
        validateProps={validateProps}
        validatePropsMessages={validatePropsMessages}
        saveShift={saveShiftV2}
        CREATE_SHIFT_EVENT={CREATE_SHIFT_EVENT}
        UPDATE_SHIFT_EVENT={UPDATE_SHIFT_EVENT}
        DELETE_SHIFT_EVENT={DELETE_SHIFT_EVENT}
        createRecommendedWorkingHours={createRecommendedWorkingHours}
        customBuffer={customBuffer}
        refetchQuery={handleRefetch}
      />
    );
  } else {
    return (
      <React.Fragment>
        <h1>{translate(null, 'my_schedule.main.title')}</h1>
        {notification && <Notification {...notification} />}
        <div className="myScheduleContainerWrapper">
          <MyScheduleContent
            key="content"
            apolloClient={apolloClient}
            scheduleTypes={scheduleTypes}
            onAddEditEvent={handleAddEditEvent}
            onOpenSettings={handleOpenSettings}
            providerShifts={providerShifts}
            setNotification={setNotification}
            createRecommendedWorkingHours={createRecommendedWorkingHours}
            canEditSchedule={canEditSchedule}
            loadSchedules={loadSchedules}
            calendarIncrement={calendarIncrement}
            refetchQuery={handleRefetch}
          />
        </div>
        {activeModal && activeModal.type === MyScheduleModals.PERSONAL_EVENTS && (
          <PersonalEventModal
            onModalClose={resetModalSubmit}
            isSubmitting={isSubmitting}
            onSubmit={handleSavePersonalEvent}
            onDelete={handleDeletePersonalEvent}
            initialWorkingHours={initialWorkingHours}
            event={activeModal.event}
            personalEvents={getSchedulesByType(scheduleTypes.PROVSCHEDEVENT_PERSONALEVENT.eventTypeCode)}
          />
        )}
      </React.Fragment>
    );
  }
};

MyScheduleContainer.propTypes = {
  providerId:      PropTypes.number.isRequired,
  apolloClient:    PropTypes.object.isRequired,
  canEditSchedule: PropTypes.bool
};

export default MyScheduleContainer;
