import React from 'react';
import { APP_FEATURES, Messaging, EmergencyAssistance } from '@teladoc/inbox';
import { authToken } from '@td/api';
import '@teladoc/inbox/dist/index.css';

const stringToBool = value => value === 'true';

const Inbox = ({ containerId, emergencyAssistance, ...rest }) => {
  const container = document.getElementById(containerId || 'active_conversations_container');
  const participantId = parseInt(container.getAttribute('data-participant-id'));
  const participantType = container.getAttribute('data-participant-type');
  const availableSpecialties = JSON.parse(container.getAttribute('data-available-specialities')) || [];
  const customerServiceRoomId = container.getAttribute('data-room-id');
  const participantRole = container.getAttribute('data-participant-role');
  const headers = { Authorization: authToken.get() };
  const locale = container.getAttribute('data-locale');
  const location = container.getAttribute('data-location');
  const features = customerServiceRoomId
    ? {
      [APP_FEATURES.footer]:        false,
      [APP_FEATURES.memberDetails]: true
    }
    : {};

  const props = {
    participantId,
    participantType,
    participantRole,
    preselectedMessageRoomId: customerServiceRoomId,
    hostUrl:                  window.SCREENFLOW_HOST,
    pubnubEnabled:            window.FEATURE_TOGGLES.pubnub_live_chat,
    pubnubSubscribeKey:       window.PUBNUB_SUBSCRIBE_KEY,
    headers,
    withCredentials:          true,
    features,
    availableSpecialties,
    locale,
    location,
    ...rest
  };

  if (emergencyAssistance) {
    const consultForm = document.getElementById('consult_form');
    const consultationId = consultForm && consultForm.getAttribute('data-consultation-id');
    const logEnabled = stringToBool(container.getAttribute('data-msg-web-logs-911'));
    const withPubnubWarning = stringToBool(container.getAttribute('data-pubnub-warning-911'));
    const pubnubWarningText = container.getAttribute('data-pubnub-warning-text');
    const emotionalRiskEnabled = stringToBool(container.getAttribute('data-emotional-risk'));
    const patientRiskEnabled = stringToBool(container.getAttribute('data-patient-risk'));

    return (
      <EmergencyAssistance
        {...props}
        withPubnubWarning={withPubnubWarning}
        pubnubWarningText={pubnubWarningText}
        logEnabled={logEnabled}
        emotionalRiskEnabled={emotionalRiskEnabled}
        patientRiskEnabled={patientRiskEnabled}
        emergencyObjectId={parseInt(consultationId)}
        emergencyObjectType="Consultation"
        isLegacyProvider
      />
    );
  }

  return <Messaging {...props} />;
};

export default Inbox;
