import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import _ from 'lodash';
import classnames from 'classnames';

import TextInput from '../../../components/TextInput';
import ConfirmationDialog from '../shared/confirmation-dialog';
import IComplaint from '../../interfaces/complaint-interface';
import ICachedComplaint from '../../interfaces/cached-complaint-interface';

class ChiefComplaint extends Component {
  constructor(props) {
    super(props);

    let complaintValue = '';

    if (this.props.cachedComplaint) {
      const { complaintText, other } = this.props.cachedComplaint;

      complaintValue = other ? 'Other: '.concat(other) : complaintText || '';
    }

    this.state = {
      complaintValue,
      otherComplaintValue: '',
      removeDialogVisible: false,
      showOther:           false,
      focus:               null
    };
  }

  componentDidMount() {
    if (this.props.cachedComplaint) {
      const { complaintText } = this.props.cachedComplaint;
      const complaintClass = this.props.complaintsClasses[complaintText];

      this.props.selectComplaint({
        ...this.props.cachedComplaint,
        refClassComplaintId: _.get(complaintClass, 'ref_class_complaint_id'),
        symptomClass:        _.get(complaintClass, 'symptom_class'),
        isComplaintSelected: true
      });
    }
  }

  onChange = (event, { newValue }) => {
    this.setState({
      complaintValue:      newValue,
      otherComplaintValue: ''
    });
  };

  onSuggestionsClearRequested = () => {
    this.props.resetComplaintsList();
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (value.trim().length > 2) {
      this.props.fetchComplaintsList(value);
    }
  };

  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      complaintValue:      suggestion.name,
      otherComplaintValue: '',
      showOther:           suggestion.name === 'Other'
    });

    this.props.selectComplaint({
      complaintId:         suggestion.id,
      complaintCd:         suggestion.complaint_cd,
      complaintText:       suggestion.name,
      other:               null,
      refClassComplaintId: _.get(this.props.complaintsClasses[suggestion.name], 'ref_class_complaint_id'),
      symptomClass:        _.get(this.props.complaintsClasses[suggestion.name], 'symptom_class'),
      isComplaintSelected: suggestion.name !== 'Other',
      noteFormat:          suggestion.note_format.code,
      durationRequired:    suggestion.duration_required,
      severityRequired:    suggestion.severity_required,
      progressionRequired: suggestion.progression_required,
      symptomsRequired:    suggestion.symptoms_required,
      diagnosisHintRequired: suggestion.diagnosis_hint_required,
      notApplicableSymptomOptionHidden: suggestion.not_applicable_symptom_option_hidden,
    });
  };

  addOtherComplaint = () => {
    const complaintClass = this.props.complaintsClasses[_.get(this.props.selectedComplaint, 'complaintText')];

    this.setState({
      showOther:      false,
      complaintValue: 'Other: '.concat(this.state.otherComplaintValue)
    });

    this.props.selectComplaint({
      other:               this.state.otherComplaintValue,
      refClassComplaintId: _.get(complaintClass, 'ref_class_complaint_id'),
      symptomClass:        _.get(complaintClass, 'symptom_class'),
      isComplaintSelected: true
    });
  };

  changeOtherComplaint = ({ target: { value } }) => {
    this.setState({ otherComplaintValue: value });
  };

  showRemoveDialog = () => {
    this.setState({ removeDialogVisible: true });
  };

  hideRemoveDialog = () => {
    this.setState({ removeDialogVisible: false });
  };

  changeComplaint = () => {
    this.setState({
      complaintValue:      '',
      otherComplaintValue: ''
    });

    this.props.clearComplaint();
  };

  render() {
    const isComplaintSelected = _.get(this.props.selectedComplaint, 'isComplaintSelected', false);
    const inputProps = {
      placeholder: 'Start typing for standard complaints',
      value:       this.state.complaintValue,
      onChange:    this.onChange,
      disabled:    isComplaintSelected,
      id:          'chief-complaint-autosuggest',
      onFocus:     () => {
        this.setState({ ...this.state, focus: true });
      },
      onBlur: () => {
        this.setState({ ...this.state, focus: false });
      }
    };

    const chiefComplaintValidationError = this.props.validationErrors.find(
      ({ validatorKey }) => validatorKey === 'chiefComplaint'
    );

    const otherChiefComplaintValidationError = this.props.validationErrors.find(
      ({ validatorKey }) => validatorKey === 'otherChiefComplaint'
    );

    return (
      <div id="chief-complaint">
        <h3>Chief Complaint</h3>
        <div className="chief-complaint-container">
          <div className="chief-complaint-wrapper">
            <div className="chief-complaint-input">
              <h4>
                <label className="label" htmlFor="medical_complaints_search">
                  Select Chief Complaint (required)
                </label>
              </h4>

              <Autosuggest
                suggestions={this.props.complaintSuggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={suggestion => suggestion.name}
                renderSuggestion={suggestion => suggestion.name}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={{
                  ...inputProps,
                  className: classnames({
                    'react-autosuggest__input': true,
                    validation_error_highlight: chiefComplaintValidationError
                  })
                }}
              />

              {!isComplaintSelected ? <p className="chief-complaint">(Enter "other" if complaint not found)</p> : null}
            </div>

            {chiefComplaintValidationError && (
              <div className="interview_inline_validation_error show validation_error">
                {chiefComplaintValidationError.message}
              </div>
            )}
          </div>

          {this.state.showOther && (
            <div>
              <div style={{ display: 'flex' }}>
                <TextInput
                  id="interview_other_complaint"
                  name="interview[other_complaint]"
                  className="other-complaint-input"
                  label="Description of Other Complaint (required)"
                  placeholder=""
                  value={this.state.otherComplaintValue}
                  onChange={this.changeOtherComplaint}
                />

                <input
                  className="add-complaint-button"
                  type="button"
                  onClick={this.addOtherComplaint}
                  value="Add"
                  disabled={this.state.otherComplaintValue.trim().length === 0}
                />
              </div>

              {otherChiefComplaintValidationError && (
                <div style={{ paddingLeft: '1em' }} className="interview_inline_validation_error show validation_error">
                  {otherChiefComplaintValidationError.message}
                </div>
              )}
            </div>
          )}

          {isComplaintSelected && (
            <input className="change-complaint-button" type="button" onClick={this.showRemoveDialog} value="Change" />
          )}
        </div>

        <ConfirmationDialog
          title="Please Note!"
          text="Editing the chief complaint will clear duration, severity, progression and
            selected symptoms. Do you want to edit?"
          visible={this.state.removeDialogVisible}
          onConfirm={this.changeComplaint}
          onClose={this.hideRemoveDialog}
        />
        {isComplaintSelected && (
          <div>
            <input
              type="hidden"
              name="interview[medical_complaints_id]"
              value={this.props.selectedComplaint.complaintId || ''}
            />
            <input
              type="hidden"
              name="interview[complaint_cd]"
              value={this.props.selectedComplaint.complaintCd || ''}
            />
            <input
              type="hidden"
              name="interview[diagnosis_hint_required]"
              value={this.props.selectedComplaint.diagnosisHintRequired || ''}
            />
            <input
              type="hidden"
              name="interview[ref_class_complaint_id]"
              value={this.props.selectedComplaint.refClassComplaintId || ''}
            />
            <input
              type="hidden"
              name="interview[medical_complaints_text]"
              value={this.props.selectedComplaint.complaintText || ''}
            />
            <input type="hidden" name="interview[other_complaint]" value={this.props.selectedComplaint.other || ''} />
            <input type="hidden" name="interview[note_format]" value={this.props.selectedComplaint.noteFormat || ''} />
            <input
              type="hidden"
              name="interview[duration_required]"
              value={this.props.selectedComplaint.durationRequired || ''}
            />
            <input
              type="hidden"
              name="interview[severity_required]"
              value={this.props.selectedComplaint.severityRequired || ''}
            />
            <input
              type="hidden"
              name="interview[progression_required]"
              value={this.props.selectedComplaint.progressionRequired || ''}
            />
            <input
              type="hidden"
              name="interview[symptoms_required]"
              value={this.props.selectedComplaint.symptomsRequired || ''}
            />
            <input
              type="hidden"
              name="interview[not_applicable_symptom_option_hidden]"
              value={this.props.selectedComplaint.notApplicableSymptomOptionHidden || ''}
            />
          </div>
        )}
      </div>
    );
  }
}

ChiefComplaint.propTypes = {
  selectedComplaint: IComplaint,
  complaintsClasses: PropTypes.shape({
    ref_class_complaint_id: PropTypes.number,
    symptom_class:          PropTypes.string
  }),
  complaintSuggestions: PropTypes.array,
  fetchComplaintsList:  PropTypes.func.isRequired,
  resetComplaintsList:  PropTypes.func.isRequired,
  selectComplaint:      PropTypes.func.isRequired,
  clearComplaint:       PropTypes.func.isRequired,
  cachedComplaint:      ICachedComplaint,
  validationErrors:     PropTypes.array
};

export default ChiefComplaint;
