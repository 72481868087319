import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@td/shared_utils';
import { get, find } from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import Loader from '../components/Loader';

import CustomCalendarEvent from './components/custom-calendar-event';
import EditModal from './components/provider-calendar-modal';
import LockedConsultModal from './components/locked-consult-modal';
import getFormattedEvents from './services/get-formatted-events';
import saveShift from './services/save-shift';

import DISPLAY_SHIFTS_QUERY from './queries/display-shifts-query.graphql';
import CREATE_SHIFT_EVENT from './mutations/create-shift-event.graphql';
import UPDATE_SHIFT_EVENT from './mutations/update-shift-event.graphql';
import DELETE_SHIFT_EVENT from './mutations/delete-shift-event.graphql';

import * as stateInit from './services/state-initialization';

import RecommendedWorkingHoursEvent from './components/recommended-working-hours-event';
import { eventTypeCodes, viewTypes } from './constants';
import { useRecommendedWorkingHours } from '../recommended-working-hours/contexts/recommended-working-hours-context';

import './styles.scss';

const HARDCODED_STRINGS = {
  APPOINTMENTTYPE_INITIAL: 'APPOINTMENTTYPE_INITIAL',
  APPOINTMENTTYPE_ONGOING: 'APPOINTMENTTYPE_ONGOING',
  availability:            'Availability'
};

HARDCODED_STRINGS.eventTitles = {
  [null]:                                      'All patient availability',
  [HARDCODED_STRINGS.APPOINTMENTTYPE_INITIAL]: 'New patient availability',
  [HARDCODED_STRINGS.APPOINTMENTTYPE_ONGOING]: 'Existing patient availability'
};

const ADD_APPOINTMENT_BLOCK_MESSAGE = {
  DEFAULT:     'provider_calendar.add_appointment_block_message',
  BEHAVHEALTH: 'provider_calendar.add_appointment_block_message_mh',
  VPC:         'provider_calendar.add_appointment_block_message_p360'
};
const localizer = momentLocalizer(moment);

// If needed switched for drag and drop
// const DnDCalendar = withDragAndDrop(Calendar);
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const ProviderCalendar = ({
  providerId,
  apolloClient,
  dedicatedAppointments,
  providerSpecialties,
  canEditSchedule = true
}) => {
  /*
   * Queries / mutations
   */
  const { loading, error, data, refetch: refetchEvents } = useQuery(DISPLAY_SHIFTS_QUERY, {
    variables: {
      providerId
    }
  });

  const specialtyCd = get(providerSpecialties, 0);

  const [callCreateShift, { loading: createShiftLoading }] = useMutation(CREATE_SHIFT_EVENT, {
    onCompleted: ({ tasCreateProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        onMutationSuccess();
        setSuccessMessage(<I18n scope="provider_calendar.mutation.create" />);
      } else {
        handleMutationError(errors);
      }
    },
    onError: error => {
      handleMutationError(error);
    }
  });

  const [callUpdateShift, { loading: updateShiftLoading }] = useMutation(UPDATE_SHIFT_EVENT, {
    onCompleted: ({ tasUpdateProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        onMutationSuccess();
        setSuccessMessage(<I18n scope="provider_calendar.mutation.update" />);
      } else {
        handleMutationError(errors);
      }
    },
    onError: error => {
      handleMutationError(error);
    }
  });

  const [callDeleteShift, { loading: deleteShiftLoading }] = useMutation(DELETE_SHIFT_EVENT, {
    onCompleted: ({ tasDeleteProviderScheduleEvent: { success, errors } }) => {
      if (success) {
        onMutationSuccess();
        setSuccessMessage(<I18n scope="provider_calendar.mutation.remove" />);
      } else {
        handleMutationError(errors);
      }
    },
    onError: error => {
      handleMutationError(error);
    }
  });

  /*
   * State
   */
  const [view, setView] = useState(viewTypes.DEFAULT_VIEW);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [formState, setFormState] = useState(stateInit.initialFormState);
  const [modalOpen, setModalOpen] = useState(false);
  const [successMessage, doSetSuccessMessage] = useState(null);
  const [lockedConsultation, setLockedConsultation] = useState(stateInit.initialLockedConsultationState);
  const [modalErrorMessage, setModalErrorMessage] = useState(stateInit.initialModalErrorMessage);
  const [formattedEvents, setFormattedEvents] = useState([]);
  const [interfaceError, setInterfaceError] = useState(null);
  const [validateProps, setValidateProps] = useState(stateInit.validationProps);
  const [validatePropsMessages, setValidatePropsMessages] = useState(stateInit.validationPropsMessages);
  const [shiftCreationBufferTime, setShiftCreationBufferTime] = useState(0);
  const [savingError, setSavingError] = useState(null);

  const { recommendedWorkingHours, refreshRecommendedWorkingHours } = useRecommendedWorkingHours();

  /*
   * Effects
   */
  useEffect(() => {
    if (data && !loading && !error) {
      const mappedSchedules = data.providerProfile.schedules.map(event => ({
        start: moment(event.startDateTime).toDate(),
        end:   moment(event.endDateTime).toDate(),
        title: dedicatedAppointments
          ? HARDCODED_STRINGS.eventTitles[event.appointmentTypeCode]
          : get(event, 'eventName', HARDCODED_STRINGS.availability),
        providerScheduleId: event.providerScheduleId,
        typeCode:           event.eventTypeCode,
        lockedConsultId:    event.actorId,
        appointmentType:    event.appointmentTypeCode
      }));


      const { appointmentTimeBlockRules } = data.providerProfile;
      const appointmentTimeBlockRulesParsed = JSON.parse(appointmentTimeBlockRules);

      setValidateProps({
        minShiftLengthSize:       appointmentTimeBlockRulesParsed.min_atb_length_size,
        maxShiftLengthSize:       appointmentTimeBlockRulesParsed.max_atb_length_size,
        shiftTimeStep:            appointmentTimeBlockRulesParsed.atb_minutes_time_step,
        shiftPreselectedDuration: appointmentTimeBlockRulesParsed.atb_minutes_preselected_duration,
        minStartDate:             appointmentTimeBlockRulesParsed.atb_minutes_time_buffer
      });

      setShiftCreationBufferTime(appointmentTimeBlockRulesParsed.atb_minutes_time_buffer / 60);

      setValidatePropsMessages({
        startDateInPast:        <I18n scope="provider_calendar.validation.start_date_in_past" />,
        endDateBeforeStartDate: <I18n scope="provider_calendar.validation.end_date_before_start_date" />,
        endTimeBeforeStartTime: <I18n scope="provider_calendar.validation.end_time_before_start_time" />,
        minShiftLength:         (
          <I18n
            scope="provider_calendar.validation"
            text="min_shift_length"
            options={{
              min_atb_length_size: appointmentTimeBlockRulesParsed.min_atb_length_size
            }}
          />
        ),
        maxShiftLength: (
          <I18n
            scope="provider_calendar.validation"
            text="max_shift_length"
            options={{
              max_atb_length_size: appointmentTimeBlockRulesParsed.max_atb_length_size / 60
            }}
          />
        ),
        overlappingShift: <I18n scope="provider_calendar.validation.overlapping_shift" />,
        minBufferTime:    (
          <I18n
            scope="provider_calendar.validation"
            text="atb_minutes_time_buffer"
            options={{
              atb_minutes_time_buffer: appointmentTimeBlockRulesParsed.atb_minutes_time_buffer / 60
            }}
          />
        )
      });

      setEvents(mappedSchedules);
      refreshRecommendedWorkingHours();
    }
  }, [data, loading, error, view]);

  useEffect(() => {
    setFormattedEvents([
      ...getFormattedEvents(events),
      ...(viewTypes.RECOMMENDED_WORKING_HOURS_VIEWS.includes(view) ? recommendedWorkingHours : [])
    ]);
  }, [view, events, recommendedWorkingHours]);

  useEffect(() => {
    if (formState.startDate) {
      setFormState(prevFormState => ({ ...prevFormState, startDate: formState.startDate }));
    }
  }, [formState.startDate]);

  useEffect(() => {
    if (formState.endDate) {
      setFormState(prevFormState => ({ ...prevFormState, endDate: formState.endDate }));
    }
  }, [formState.endDate]);

  useEffect(() => {
    if (selectedEvent && canEditSchedule) {
      if (selectedEvent.end.getTime() <= new Date().getTime()) {
        // cannot edit / delete shifts that ended in the past
        return;
      }
      openModal(selectedEvent);
    }
  }, [selectedEvent]);

  const resolveEventProps = useCallback(
    event => ({
      className:
        event.typeCode === 'PROVSCHEDEVENT_AWM'
          ? 'provider-calendar-locked-consult-wrapper'
          : `event__${event.typeCode.toLocaleLowerCase()}`
    }),
    []
  );

  /*
   * Callbacks
   */
  const handleMutationError = mutationError => {
    setModalErrorMessage(true);
    setSavingError(get(mutationError, 0, 'An error occurred while trying to save.'));
  };

  const onSelectTimeRange = range => {
    if (range.start.getTime() <= new Date().getTime() || !canEditSchedule) {
      // cannot create shifts starting in the past
      return;
    }
    openModal(range);
  };

  const onMutationSuccess = () => {
    closeModal();

    refetchEvents();
    refreshRecommendedWorkingHours();
  };

  const setSuccessMessage = message => {
    doSetSuccessMessage(message);
    const timeout = setTimeout(() => {
      doSetSuccessMessage(null);
    }, 5000);
    return () => clearTimeout(timeout);
  };

  const allowSave = !!(formState.startDate && formState.startTime && formState.endDate && formState.endTime);

  const handleSaveClick = () => {
    if (allowSave) {
      setSavingError(null);
      setInterfaceError(null);

      return saveShift({
        formState,
        selectedEvent,
        formattedEvents,
        validateProps,
        validatePropsMessages,
        setInterfaceError,
        setModalErrorMessage,
        callCreateShift,
        callUpdateShift
      });
    } else {
      return null;
    }
  };

  const openModal = defaultStartEnd => {
    let start;
    let end;
    let editableSelectedEvent;

    // Check if event spreads through 2 days and set data from original event by ID
    if (defaultStartEnd && defaultStartEnd.providerScheduleId) {
      editableSelectedEvent = find(events, event => event.providerScheduleId === defaultStartEnd.providerScheduleId);
    }

    // Set editable event or initiate new with default values
    if (editableSelectedEvent || (defaultStartEnd && defaultStartEnd.start && defaultStartEnd.end)) {
      start = editableSelectedEvent ? moment(editableSelectedEvent.start) : moment(defaultStartEnd.start);
      end = editableSelectedEvent ? moment(editableSelectedEvent.end) : moment(defaultStartEnd.end);
      const appointmentType =
        editableSelectedEvent && editableSelectedEvent.typeCode === 'PROVSCHEDEVENT_ATB'
          ? editableSelectedEvent.appointmentType
          : null;

      setFormState({
        startDate: start.format('YYYY-MM-DD'),
        startTime: moment(start, ['h:mm A']).toDate(),
        endDate:   end.format('YYYY-MM-DD'),
        endTime:   moment(end, ['h:mm A']).toDate(),
        appointmentType
      });
    } else {
      const startTime = moment()
        .add(1, 'hours')
        .startOf('hour');
      const endTime = moment()
        .add(1 + validateProps.shiftPreselectedDuration / 60, 'hours')
        .startOf('hour');

      const startDate = moment(moment().add(shiftCreationBufferTime, 'days'));
      const endDate = startDate;

      setFormState({
        startDate:       startDate.format('YYYY-MM-DD'),
        startTime:       moment(startTime, ['h:mm A']).toDate(),
        endDate:         endDate.format('YYYY-MM-DD'),
        endTime:         moment(endTime, ['h:mm A']).toDate(),
        appointmentType: null
      });
    }

    setLockedConsultation(
      selectedEvent && !!selectedEvent.lockedConsultId && selectedEvent.typeCode === 'PROVSCHEDEVENT_AWM'
    );
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setFormState(stateInit.initialFormState);
    setSelectedEvent(null);
    setModalErrorMessage(false);
    setInterfaceError(null);
  };

  // Catch event and get original event data for events spreaded through 2 days
  const handleSelectEvent = event => {
    const originalEvent = find(events, singleEvent => singleEvent.providerScheduleId === event.providerScheduleId);
    const modifiedSelectedEvent = {
      ...event,
      start: originalEvent ? originalEvent.start : event.start,
      end:   originalEvent ? originalEvent.end : event.end
    };
    setSelectedEvent(modifiedSelectedEvent);
  };

  /*
   * Rendering
   */

  if (loading) {
    return <Loader />;
  }

  const loadingError = (!data || error) && 'Data could not be loaded';
  if (loadingError) {
    return <div className="error">{loadingError}</div>;
  }

  return (
    <React.Fragment>
      {successMessage && <div className="success">{successMessage}</div>}
      <div>
        {canEditSchedule ? (
          <React.Fragment>
            <p style={{ marginBottom: 15 }}>
              <I18n scope="provider_calendar.add_appointment_block_message" />
            </p>
            <a
              className="provider-calendar-appointment-button button primary javascript_link"
              style={{ marginBottom: 20 }}
              onClick={() => openModal()}
            >
              <I18n scope="provider_calendar.add_appointment_block" />
            </a>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p style={{ marginBottom: 15 }}>
              <I18n scope={ADD_APPOINTMENT_BLOCK_MESSAGE[specialtyCd] || ADD_APPOINTMENT_BLOCK_MESSAGE.DEFAULT} />
            </p>
          </React.Fragment>
        )}
      </div>
      <div className="providerCalendar">
        <Calendar
          defaultDate={moment().toDate()}
          views={viewTypes.AVAILABLE_VIEWS}
          view={view}
          onView={setView}
          showMultiDayTimes
          events={formattedEvents}
          localizer={localizer}
          resizable
          selectable
          timeslots={1}
          step={60}
          style={{ height: 700 }}
          apolloClient={apolloClient}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={onSelectTimeRange}
          eventPropGetter={resolveEventProps}
          components={{
            event: ({ event }) => {
              if (event.typeCode === eventTypeCodes.RECOMMENDED_WORKING_HOURS) {
                return <RecommendedWorkingHoursEvent event={event} isProviderCalendarV2={false} />;
              }

              return <CustomCalendarEvent event={event} />;
            }
          }}
        />
      </div>
      {lockedConsultation && modalOpen ? (
        <LockedConsultModal closeModal={closeModal} selectedEvent={selectedEvent} stateInit={stateInit} />
      ) : (
        <EditModal
          formState={formState}
          setFormState={setFormState}
          selectedEvent={selectedEvent}
          validateProps={validateProps}
          validatePropsMessages={validatePropsMessages}
          interfaceError={interfaceError}
          setInterfaceError={setInterfaceError}
          modalErrorMessage={modalErrorMessage}
          setModalErrorMessage={setModalErrorMessage}
          callCreateShift={callCreateShift}
          callUpdateShift={callUpdateShift}
          callDeleteShift={callDeleteShift}
          closeModal={closeModal}
          modalOpen={modalOpen}
          savingError={savingError}
          createShiftLoading={createShiftLoading}
          updateShiftLoading={updateShiftLoading}
          deleteShiftLoading={deleteShiftLoading}
          handleSaveClick={handleSaveClick}
          allowSave={allowSave}
          dedicatedAppointments={dedicatedAppointments}
        />
      )}
    </React.Fragment>
  );
};

ProviderCalendar.propTypes = {
  providerId:            PropTypes.number.isRequired,
  apolloClient:          PropTypes.object.isRequired,
  dedicatedAppointments: PropTypes.bool,
  providerSpecialties:   PropTypes.array,
  canEditSchedule:       PropTypes.bool
};

export default ProviderCalendar;
