import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { translate } from '@td/shared_utils';
import moment from 'moment';

// Icons
const trashIcon = require('../../assets/images/trash_icon.svg');

// I18n
const TRANSLATION_SCOPE = 'my_schedule.modals.regular_working_hours.days_and_times_section';

const renderTimePicker = ({ selectedTime, type, label, onChange }) => (
  <DatePicker
    selected={selectedTime}
    onChange={date => onChange(type, date)}
    showTimeSelect
    showTimeSelectOnly
    className={`${type}TimePicker`}
    timeIntervals={15}
    id={`${type}Time`}
    name={`${type}Time`}
    valueName={`${type}Time`}
    dateFormat="h:mm aa"
    timeCaption={label}
    popperPlacement="right"
  />
);
renderTimePicker.propTypes = {
  selectedTime: PropTypes.object,
  type:         PropTypes.string,
  isInit:       PropTypes.bool,
  label:        PropTypes.string,
  dayName:      PropTypes.string,
  index:        PropTypes.number,
  onChange:     PropTypes.func.isRequired
};

const checkValidTimes = ({ startDateTime, endDateTime }) => {
  const isValidMinSize = moment(endDateTime).diff(moment(startDateTime), 'minutes') >= 15;
  const isValidMaxSize = moment(endDateTime).diff(moment(startDateTime), 'hours', true) <= 12;
  return startDateTime && endDateTime && isValidMinSize && isValidMaxSize;
};

const RegularWorkingHoursTimes = ({ timeBlock, onDelete, onTimeChange }) => {
  const [userTimeBlock, setUserTimeBlock] = useState({
    ...timeBlock,
    startDateTime:
      timeBlock.startDateTime ||
      moment()
        .startOf('day')
        .set('hour', 8),
    endDateTime:
      timeBlock.endDateTime ||
      moment()
        .startOf('day')
        .set('hour', 9),
    isValid: true
  });

  useEffect(() => {
    setUserTimeBlock(onTimeChange(userTimeBlock));
  }, []);

  const handleTimeChange = (type, date) => {
    const momentDate = moment(date);
    setUserTimeBlock(prevUserTimeBlock => {
      const endDateTime =
        type === 'start'
          ? prevUserTimeBlock.endDateTime
          : moment(prevUserTimeBlock.endDateTime).set({
            hours:   momentDate.hours(),
            minutes: momentDate.minutes()
          });
      const startDateTime =
        type === 'end'
          ? prevUserTimeBlock.startDateTime
          : moment(prevUserTimeBlock.startDateTime).set({
            hours:   momentDate.hours(),
            minutes: momentDate.minutes()
          });
      const isValid = checkValidTimes({ startDateTime, endDateTime });

      const newUserTimeBlock = { ...prevUserTimeBlock, startDateTime, endDateTime, isValid };

      return onTimeChange(newUserTimeBlock);
    });
  };

  const isValid = userTimeBlock.isValid && userTimeBlock.isNonOverlapping;

  return (
    <div className={`timeSlotTime ${!isValid && 'invalidTime'}`}>
      <div className="startEndTimeWrapper">
        {renderTimePicker({
          selectedTime: moment(userTimeBlock.startDateTime).toDate(),
          type:         'start',
          label:        translate(null, TRANSLATION_SCOPE, 'start_label'),
          onChange:     handleTimeChange
        })}
        <div className="separator" />
        {renderTimePicker({
          selectedTime: moment(userTimeBlock.endDateTime).toDate(),
          type:         'end',
          label:        translate(null, TRANSLATION_SCOPE, 'end_label'),
          onChange:     handleTimeChange
        })}

        {userTimeBlock.isValid && (
          <div className="timeSlotActions">
            <img
              className="deleteAction"
              src={trashIcon}
              onClick={() => onDelete(userTimeBlock)}
              alt={translate(null, 'my_schedule.settings_page', 'actions.delete')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
RegularWorkingHoursTimes.propTypes = {
  timeBlock: PropTypes.shape({
    startDateTime: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string]),
    endDateTime:   PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(moment), PropTypes.string])
  }).isRequired,
  onDelete:     PropTypes.func.isRequired,
  onTimeChange: PropTypes.func.isRequired
};

export default RegularWorkingHoursTimes;
