class AdHocQuestionContent {
  constructor({ sectionCd, questionCd, content }) {
    this.sectionCd = sectionCd;
    this.questionCd = questionCd;
    this.content = content;
  };

  render({ sectionCd, questionCd }) {
    if (sectionCd === this.sectionCd && questionCd === this.questionCd) return this.content;

    return '';
  };
}

export default AdHocQuestionContent;
