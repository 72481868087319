import { getSesMemberSpecialtiesConfig } from '../helpers';
import fetchSesEligibility from './fetch-ses-eligibility';
import fetchTasEligibility from './fetch-tas-eligibility';

const fetchMemberEligibility = (member) => {
  if (getSesMemberSpecialtiesConfig().enabled) {
    return fetchSesEligibility(member);
  }

  return fetchTasEligibility(member);
};

export default fetchMemberEligibility;
