import { I18n, translate } from '@td/shared_utils';
import React, { Fragment, useEffect, useState } from 'react';
import Loader from 'app/components/Loader';
import { NameAttributes } from '../dom-integration';
import FollowupAppointment from './followup-appointment';
import FollowupSpecialties from './followup-specialties';
import ExternalServicesSelector from './external-services-selector';
import { FOLLOWUP_SECTIONS } from '../constants';
import FieldError from './field-error';
import { isServiceReferrable } from '../helpers';

export const TRANSLATION_SCOPE = 'consult_followup';

const ConsultFollowup = ({
  providerScheduledAppointmentAllowed,
  continueTreatment,
  patientManagementToolsV2Enabled,
  isMentalHealth,
  noFurtherCareMaxDays,
  memberHasPcp,
  fieldErrors,
  consultSpecialtyName,
  teladocServices,
  nonTeladocServicesFetch,
  isError,
  isLoading,
  selectedTeladocServices,
  selectedNonTeladocService,
  setSelectedNonTeladocService,
  noFurtherCareDays,
  setNoFurtherCareDays,
  selectedAppointmentOption,
  setSelectedAppointmentOption,
  p2pNote,
  setP2pNote,
  terminateReasonCode,
  isMyleIntegrated
}) => {
  const [continueTreatmentForMember, setContinueTreatmentForMember] = useState(continueTreatment);
  const [terminationReasonCode, setTerminationReasonCode] = useState(terminateReasonCode);
  const pclmIntegrationEnabled = patientManagementToolsV2Enabled && isMentalHealth;
  const [displayFollowUpAppt, setDisplayFollowUpAppt] = useState(true);

  const getSpecialties = () =>
    (teladocServices || []).filter(service =>
      isServiceReferrable(service, consultSpecialtyName),
    );

  useEffect(() => {
    if (pclmIntegrationEnabled) {
      const updateContinueTreatment = e => {
        setContinueTreatmentForMember(e.target.value);
      };

      const updateTerminateReasonCodes = e => {
        setTerminationReasonCode(e.target.value);
      };

      document.querySelectorAll('input[name="interview[continue_treatment]"]').forEach(elem => {
        elem.addEventListener('change', e => {
          updateContinueTreatment(e);
        });
      });

      document.getElementById('interview_terminate_reason_cd').addEventListener('change', e => {
        updateTerminateReasonCodes(e);
      });
    }
  }, []);

  useEffect(() => {
    if (continueTreatmentForMember === 'no') {
      setSelectedAppointmentOption('');
      setNoFurtherCareDays('');
    }
    if (!isMentalHealth || continueTreatmentForMember !== 'no' || !patientManagementToolsV2Enabled) {
      setDisplayFollowUpAppt(true);
    } else {
      setDisplayFollowUpAppt(false);
    }
  }, [continueTreatmentForMember]);

  const followupApptClassName = `mb-4 followup-appointment ${displayFollowUpAppt ? '' : 'hidden'}`;

  return (
    <div>
      {!patientManagementToolsV2Enabled && (
        <Fragment>
          <div className="mb-4">
            <h3 className="p-0">
              <I18n scope={TRANSLATION_SCOPE} text="discuss_reminder.heading" />
            </h3>
            <ul className="mt-2 pl-6" style={{ listStyle: 'disc' }}>
              <li>
                <I18n scope={TRANSLATION_SCOPE} text="discuss_reminder.treatment_plan" />
              </li>
              <li className="mt-2">
                <I18n scope={TRANSLATION_SCOPE} text="discuss_reminder.expected_course_of_illness" />
              </li>
              <li className="mt-2">
                <I18n scope={TRANSLATION_SCOPE} text="discuss_reminder.signs_and_symptoms" />
              </li>
              <li className="mt-2">
                <I18n scope={TRANSLATION_SCOPE} text="discuss_reminder.member_questions" />
              </li>
            </ul>
          </div>
          <div className="mb-4">
            <h3 className="p-0">
              <I18n scope={TRANSLATION_SCOPE} text="disclamer_for_members.heading" />
            </h3>
            <div className="mt-2">
              <I18n scope={TRANSLATION_SCOPE} text="disclamer_for_members.text" />
            </div>
          </div>
        </Fragment>
      )}

      {isError && (
        <div>
          <I18n scope={TRANSLATION_SCOPE} text="failed_to_load" />
        </div>
      )}

      {!isError && isLoading && <Loader />}
      {!isError && !isLoading && (
        <Fragment>
          <FollowupAppointment
            className={followupApptClassName}
            providerScheduledAllowed={providerScheduledAppointmentAllowed}
            noFurtherCareDays={noFurtherCareDays}
            setNoFurtherCareDays={setNoFurtherCareDays}
            noFurtherCareMaxDays={noFurtherCareMaxDays}
            fieldErrors={fieldErrors}
            selectedValue={selectedAppointmentOption}
            setSelectedValue={setSelectedAppointmentOption}
            patientManagementToolsV2Enabled={patientManagementToolsV2Enabled}
            isMentalHealth={isMentalHealth}
            isMyleIntegrated={isMyleIntegrated}
          />

          <h3 className="p-0 mb-2">
            <I18n
              scope={TRANSLATION_SCOPE}
              text={pclmIntegrationEnabled ? 'referrals.mental_health_heading' : 'referrals.heading'}
            />
          </h3>

          {!patientManagementToolsV2Enabled && (
            <Fragment>
              <p className="mb-2 font-bold" style={{ color: '#244861' }}>
                <I18n scope={TRANSLATION_SCOPE} text="referrals.would_patient_benefit" />
              </p>
              <p>
                {' '}
                <I18n scope={TRANSLATION_SCOPE} text="referrals.select_as_many" />{' '}
              </p>
            </Fragment>
          )}

          {pclmIntegrationEnabled && (
            <p>
              {' '}
              <I18n scope={TRANSLATION_SCOPE} text="referrals.patient_is_eligible" />{' '}
            </p>
          )}

          <FollowupSpecialties
            className="followup-teladoc-services"
            specialties={getSpecialties()}
            isServiceSelected={selectedTeladocServices.isSelected}
            onServiceClick={selectedTeladocServices.onItemClick}
            pclmIntegrationEnabled={pclmIntegrationEnabled}
          />
          <div className="followup-teladoc-services-errors">
            {fieldErrors
              .filter(e => e.validatorKey === FOLLOWUP_SECTIONS.TELADOC_SERVICES)
              .map((e, i) => (
                <FieldError style={{ margin: '8px 0' }} key={i} message={e.message} />
              ))}
          </div>

          {pclmIntegrationEnabled ? (
            <Fragment>
              <br />
              <p className="font-bold mt-4 mb-8">
                <I18n
                  scope={TRANSLATION_SCOPE}
                  text={pclmIntegrationEnabled ? 'p2p_note.heading_pclm' : 'p2p_note.heading'}
                />
              </p>
              <textarea
                className="followup-textarea"
                value={p2pNote}
                onChange={e => setP2pNote(e.target.value)}
                name={NameAttributes.referrals.teladocServices.p2pNote.html}
                placeholder={translate(null, TRANSLATION_SCOPE, 'p2p_note.placeholder')}
              />

              <ExternalServicesSelector
                className="mt-5"
                memberHasPcp={memberHasPcp}
                services={nonTeladocServicesFetch.data.displayCodeMappings}
                selectedService={selectedNonTeladocService}
                setSelectedService={setSelectedNonTeladocService}
                fieldErrors={fieldErrors}
                consultSpecialtyName={consultSpecialtyName}
                terminationReasonCode={terminationReasonCode}
                pclmIntegrationEnabled={pclmIntegrationEnabled}
              />
            </Fragment>
          ) : (
            <Fragment>
              <ExternalServicesSelector
                className="mt-5"
                memberHasPcp={memberHasPcp}
                services={nonTeladocServicesFetch.data.displayCodeMappings}
                selectedService={selectedNonTeladocService}
                setSelectedService={setSelectedNonTeladocService}
                fieldErrors={fieldErrors}
                consultSpecialtyName={consultSpecialtyName}
                terminationReasonCode={terminationReasonCode}
                pclmIntegrationEnabled={pclmIntegrationEnabled}
              />

              <p className="font-bold mt-4 mb-8">
                <I18n
                  scope={TRANSLATION_SCOPE}
                  text={pclmIntegrationEnabled ? 'p2p_note.heading_pclm' : 'p2p_note.heading'}
                />
              </p>
              <textarea
                className="followup-textarea"
                value={p2pNote}
                onChange={e => setP2pNote(e.target.value)}
                name={NameAttributes.referrals.teladocServices.p2pNote.html}
                placeholder={translate(null, TRANSLATION_SCOPE, 'p2p_note.placeholder')}
              />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default ConsultFollowup;
