import {
  FETCH_MEMBER_SPECIALTIES_FAILED,
  FETCH_MEMBER_SPECIALTIES_FULFILLED,
  FETCH_MEMBER_SPECIALTIES_STARTED,
} from './action-types';

const SET_CONSULT_FOLLOWUP_STATE = 'SET_CONSULT_FOLLOWUP_STATE';

export const setFollowupReduxState = newState => ({ type: SET_CONSULT_FOLLOWUP_STATE, payload: newState });

const initialState = {
  teladocServices:           [],
  teladocServicesLoading:    false,
  teladocServicesError:      undefined,
  noFurtherCareMinDays:      undefined,
  noFurtherCareMaxDays:      undefined,
  communicationMethod:       undefined,
  noFurtherCareDays:         '',
  selectedAppointmentOption: undefined,
  selectedTeladocServices:   [],
  selectedNonTeladocService: undefined
};

export const consultFollowupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONSULT_FOLLOWUP_STATE:
      return { ...state, ...action.payload };
    case FETCH_MEMBER_SPECIALTIES_STARTED:
      return { ...state, teladocServicesLoading: true, teladocServicesError: undefined };
    case FETCH_MEMBER_SPECIALTIES_FULFILLED:
      return {
        ...state,
        teladocServices: action.payload,
        teladocServicesLoading: false,
        teladocServicesError: undefined,
      };
    case FETCH_MEMBER_SPECIALTIES_FAILED:
      return { ...state, teladocServicesLoading: false, teladocServicesError: action.payload.error };
    default:
      return state;
  }
};
