import getClientInstance from '../../services/apollo-client';

let graphqlClient;

const getGraphqlClient = () => {
  if (!graphqlClient) {
    graphqlClient = getClientInstance();
  }

  return graphqlClient;
};

export default getGraphqlClient;
