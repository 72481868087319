import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ProviderClockInButton from './provider-clock-in-button-container';
import ProviderCancelButtonContainer from './provider-cancel-button-container';
import LocationForm from './provider-clock-in-location-form';
import ProviderClockInRequest from '../enums/provider-clock-in-request';

import { clockInProviderInit } from '../actions';
import { PROVIDERLOCATION_OTHER } from '../constants';

import Button from '../../../components/Button';

const ProviderClockInLocation = ({
  locationType,
  locationText,
  locationList,
  requestClockInType,
  onProviderClockInButtonClick,
  onCancelClick,
  withClockInButtons,
  onSendLocationClick
}) => {
  const [currentLocationType, setCurrentLocationType] = useState(locationType.code);
  const [currentLocationText, setCurrentLocationText] = useState(locationText);
  const [validationError, setValidationError] = useState('');
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    validateLocation();
  }, [currentLocationType, currentLocationText]);

  const handleLocationTypeChange = e => {
    setCurrentLocationType(e.target.value);
  };

  const handleLocationTextChange = e => {
    setCurrentLocationText(e.target.value);
  };

  const handleButtonClick = () => {
    onSendLocationClick(currentLocationType, currentLocationText);
  };

  const handleProviderClockInButtonClick = clockInType => event => {
    onProviderClockInButtonClick(clockInType, currentLocationType, currentLocationText);
  };

  const validateLocation = () => {
    if (currentLocationType === PROVIDERLOCATION_OTHER) {
      validateOtherLocation();
    } else {
      validateNotOtherLocation();
    }
  };

  const validateNotOtherLocation = () => {
    if (!currentLocationType) {
      setValidationError('');
      setDisableButton(true);
    } else {
      validPast();
    }
  };

  const validateOtherLocation = () => {
    if (!currentLocationText) {
      setValidationError('');
      setDisableButton(true);
    } else if (currentLocationText.length < 2 || currentLocationText.length > 15) {
      setValidationError('Please make sure your location is at least 2 characters, but no more than 15 characters');
      setDisableButton(true);
    } else if (!/^[\w\s]*[a-zA-Z][\w\s]*$/.test(currentLocationText)) {
      setValidationError(
        'Please make sure you use only Latin characters, digits and spaces. Only spaces or only digits are not allowed.'
      );
      setDisableButton(true);
    } else {
      validPast();
    }
  };

  const validPast = () => {
    setValidationError('');
    setDisableButton(false);
  };

  return (
    <div className="clock-in-location-form">
      <LocationForm
        locationList={locationList}
        currentLocationType={currentLocationType}
        currentLocationText={currentLocationText}
        handleLocationTypeChange={handleLocationTypeChange}
        handleLocationTextChange={handleLocationTextChange}
        validationError={validationError}
      />
      {withClockInButtons ? (
        <section className="clock-in-out-buttons">
          <ProviderClockInButton
            clockInType={requestClockInType}
            onClick={handleProviderClockInButtonClick(requestClockInType)}
            disableButton={disableButton}
          />
          <ProviderCancelButtonContainer onClick={onCancelClick} />
        </section>
      ) : (
        <Button
          className="send-location-button"
          onClick={handleButtonClick}
          disabled={disableButton}
          text="Send location"
        />
      )}
    </div>
  );
};

ProviderClockInLocation.propTypes = {
  locationType:                 PropTypes.object,
  locationText:                 PropTypes.string,
  locationList:                 PropTypes.array,
  requestClockInType:           PropTypes.string,
  onProviderClockInButtonClick: PropTypes.func
};

ProviderClockInLocation.defaultProps = {
  withClockInButtons:  true,
  onSendLocationClick: () => {}
};

export default ProviderClockInLocation;
