import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EHR from '@td/ehr';
import { setEhrIsLoaded, setViewAllMentalHealthDocuments } from './redux/actions';

// Import the CSS from EHR library
import '@td/ehr/dist/index.css';

import { EhrFileUploadsContainer } from 'app/FileUploads/components/ehr-file-uploads-container';

/* isSectionRequired - Utility function */
const isSectionRequired = node =>
  (node.className && node.className.includes('review_required')) ||
  (node.firstChild && node.firstChild.className && node.firstChild.className.includes('review_required'));

const EHRWrapper = ({
  containerId,
  consultationId,
  memberId,
  fullDetails,
  activeConsult = false,
  reviewCompleted = false,
  expandFirstSection = false,
  editable = false,
  setEhrIsLoaded,
  setViewAllMentalHealthDocuments,
  ...extraProps
}) => {
  const onLoad = () => {
    const sections = $(`#${containerId} div.ct`).filter((_, el) => !el.closest('.react_gm_plus_section'));

    // TODO CKAN-1843: Move all of this EHR toggling code from the coffeescript to react
    new EhrToggler(sections, activeConsult, reviewCompleted, containerId).toggle();

    if (!activeConsult) {
      // collapse all EHR sections
      sections.find('.ehr_content').hide();

      if (expandFirstSection) {
        $(`#${containerId} h3:first`).click();
      }
    }

    if (
      window.location.hash.substr(1) === 'lab_tests_section' &&
      $(`#${containerId} #pathology_lab_tests ehr_content`).length === 0
    ) {
      // Expand lab tests
      // This is a one off case, if we need to do this more often then
      // should implement cleaner solution
      $(`#${containerId} #pathology_lab_tests.ct h3:first`).click();
    }

    const container = document.querySelector(`#${containerId} .ehr_section_container`);
    if (activeConsult && window.FEATURE_TOGGLES.provider_ehr_reordering) {
      // Sort required sections to first
      const sections = Array.from(container.childNodes);
      sections.sort((section1, section2) => {
        const section1isRequired = isSectionRequired(section1);
        const section2isRequired = isSectionRequired(section2);
        if (section1isRequired && !section2isRequired) return -1;
        if (section2isRequired && !section1isRequired) return 1;
        return 0;
      });
      container.append(...sections);
    }

    // Show the EHR
    $(container).show();
    setEhrIsLoaded();
  };

  // inject section-specific context / dependencies
  const sectionSpecificContext = {
    react_images_and_documents_section: { EhrFileUploadsContainer }
  };

  const onViewAllMentalHealthDocuments = () => {
    setViewAllMentalHealthDocuments();
  };

  let extraPropsMentalHealth = {};
  if (containerId == 'consultation_ehr') {
    extraPropsMentalHealth = {
      mentalHealthDocuments: {
        onViewAllMentalHealthDocuments
      }
    };
  }

  const props = {
    userType: 'Provider',
    containerId,
    consultationId,
    memberId,
    activeConsult,
    fullDetails,
    editable,
    onLoad,
    sectionSpecificContext,
    ...extraProps,
    ...extraPropsMentalHealth
  };
  return <EHR {...props} />;
};

EHRWrapper.propTypes = {
  containerId:        PropTypes.string.isRequired,
  consultationId:     PropTypes.number.isRequired,
  memberId:           PropTypes.number.isRequired,
  activeConsult:      PropTypes.bool,
  fullDetails:        PropTypes.bool,
  editable:           PropTypes.bool,
  expandFirstSection: PropTypes.bool,
  reviewCompleted:    PropTypes.bool
};

const mapDispatchToProps = {
  setEhrIsLoaded,
  setViewAllMentalHealthDocuments
};

export default connect(null, mapDispatchToProps)(EHRWrapper);
