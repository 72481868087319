exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "._3Ubt0g32Hz8rvOLeS2bBv_ {\n  flex-direction: column;\n  align-items: flex-start !important; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._1QnqRZTXhqYIW6g9hq17EI {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-row-gap: 6px;\n    margin-left: 2px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._3g6ZJfCT76ulVM2uwJY8jo {\n    color: #244861;\n    background: #F3F3F3;\n    font-size: 10px;\n    height: 11px;\n    text-transform: uppercase;\n    font-weight: bold;\n    margin-bottom: 9px;\n    padding: 6px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._1KQL6xwfw9NZ13rFSJYc-M:not(:first-child) {\n    margin-top: 16px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._1KQL6xwfw9NZ13rFSJYc-M:first-child {\n    margin-top: 12px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._26t40ImF22Ml7jIYsEvwdF {\n    display: flex;\n    flex-direction: row;\n    align-items: center; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._-b-UHC7AZVwnepYKZU7FU {\n    display: inline-flex;\n    margin: 0 !important; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._22LhaPIw-O6jWbeyymDDGM {\n    margin-right: 5px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._2HCQzfQCmqFmcAxjHuSDNc {\n    display: flex;\n    flex-direction: column;\n    margin-left: 20px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._1t8eI36yscINbZuH__X817:not(:first-child) {\n    margin-left: 19px; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._3foEKmXNZQ4WPFRLV2Mwrt {\n    margin-left: 4px;\n    padding: 0 5px;\n    color: #244861;\n    background: #E5F4FA;\n    border-radius: 4px;\n    display: inline-block;\n    font-size: 10px;\n    line-height: 16px;\n    box-sizing: border-box; }\n  ._3Ubt0g32Hz8rvOLeS2bBv_ ._1XLrtzlRlNOUdKrtuvxH0- {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: -1px; }\n", ""]);

// exports
exports.locals = {
	"followupSpecialtiesRoot": "_3Ubt0g32Hz8rvOLeS2bBv_",
	"followupSpecialtiesRoot": "_3Ubt0g32Hz8rvOLeS2bBv_",
	"followupSpecialtiesItems": "_1QnqRZTXhqYIW6g9hq17EI",
	"followupSpecialtiesItems": "_1QnqRZTXhqYIW6g9hq17EI",
	"specialtiesGroupTitle": "_3g6ZJfCT76ulVM2uwJY8jo",
	"specialtiesGroupTitle": "_3g6ZJfCT76ulVM2uwJY8jo",
	"specialtiesGroup": "_1KQL6xwfw9NZ13rFSJYc-M",
	"specialtiesGroup": "_1KQL6xwfw9NZ13rFSJYc-M",
	"specialty": "_26t40ImF22Ml7jIYsEvwdF",
	"specialty": "_26t40ImF22Ml7jIYsEvwdF",
	"specialtyInputWrapper": "_-b-UHC7AZVwnepYKZU7FU",
	"specialtyInputWrapper": "_-b-UHC7AZVwnepYKZU7FU",
	"specialtyName": "_22LhaPIw-O6jWbeyymDDGM",
	"specialtyName": "_22LhaPIw-O6jWbeyymDDGM",
	"specialtyFeatures": "_2HCQzfQCmqFmcAxjHuSDNc",
	"specialtyFeatures": "_2HCQzfQCmqFmcAxjHuSDNc",
	"singleSpecialtyFeature": "_1t8eI36yscINbZuH__X817",
	"singleSpecialtyFeature": "_1t8eI36yscINbZuH__X817",
	"specialtyTag": "_3foEKmXNZQ4WPFRLV2Mwrt",
	"specialtyTag": "_3foEKmXNZQ4WPFRLV2Mwrt",
	"specialtyInfoIcon": "_1XLrtzlRlNOUdKrtuvxH0-",
	"specialtyInfoIcon": "_1XLrtzlRlNOUdKrtuvxH0-"
};