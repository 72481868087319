import React from 'react';
import PropTypes from 'prop-types';
import ChiefComplaint from './chief-complaint/chief-complaint-container';
import DurationSeverityProgression from './duration-severity-progression/duration-severity-progression-container';
import Symptoms from './symptoms/symptoms-container';
import SystemGeneratedNote from './system-generated-note/system-generated-note-container';
import AdditionalNotes from './additional-notes/additional-notes-container';
import ICachedComplaint from '../interfaces/cached-complaint-interface';
import ClinicalAlerts from 'app/clinical-alerts/components/clinical-alerts-container';
import { ClinicalGuideline, CdcRecommendedGuideline } from '../components';
import { TRAVEL_MEDICATION_COMPLAINT_CODE } from '../constants';
import TravelMedicationSurvey from '../sections/travel-medication-survey';
import ComplaintType from '../interfaces/complaint-interface';
import '../styles.scss';

const SubjectiveTab = ({
  requestReason,
  patientGender,
  patientAge,
  specialty,
  isComplaintSelected,
  cachedComplaint,
  cachedClinicalAlerts,
  clinicalGuideline,
  medicalCodeServiceSpecialtyRelation,
  complaint
}) => (
  <div id="subjective-tab">
    <h2>Subjective</h2>
    <h4>Patient-Provided Reason for Visit</h4>
    <p className="request-reason">
      {requestReason}
    </p>
    {clinicalGuideline && (
      <ClinicalGuideline
        clinicalGuideline={clinicalGuideline}
      />
    )}
    {medicalCodeServiceSpecialtyRelation && (
      <CdcRecommendedGuideline
        medicalCodeServiceSpecialtyRelation={medicalCodeServiceSpecialtyRelation}
      />
    )}
    <ChiefComplaint specialty={specialty} cachedComplaint={cachedComplaint} />

    {complaint && complaint.complaintCd === TRAVEL_MEDICATION_COMPLAINT_CODE && (
      <TravelMedicationSurvey />
    )}

    <div id="complaint-information" style={{ display: isComplaintSelected ? 'block' : 'none' }}>
      <DurationSeverityProgression />
      
      {complaint && complaint.symptomsRequired && (
        <Symptoms />
      )}
      
      <ClinicalAlerts cachedClinicalAlerts={cachedClinicalAlerts} />
      <SystemGeneratedNote patientGender={patientGender} patientAge={patientAge} />
    </div>
    <AdditionalNotes />
  </div>
);

SubjectiveTab.propTypes = {
  complaint:            ComplaintType,
  requestReason:        PropTypes.string,
  patientGender:        PropTypes.string,
  patientAge:           PropTypes.number,
  specialty:            PropTypes.string.isRequired,
  isComplaintSelected:  PropTypes.bool,
  cachedComplaint:      ICachedComplaint,
  cachedClinicalAlerts: PropTypes.array,
  clinicalGuideline:    PropTypes.shape({
    url: PropTypes.string,
  }),
  medicalCodeServiceSpecialtyRelation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    medicalCode: PropTypes.string,
    specialtyCd: PropTypes.string,
  }),
};

export default SubjectiveTab;
